<template>
    <div id="logistic-page">
        <Grid :column="1" :gap="20">
            <Block padding="5">
                <div class="flex">
                    <div class="pag">
                        <!-- <div>страница: {{ currentPage }} из {{ goodsTotalList.length }}</div> -->
                        <v-pagination :total-visible="5" v-model="currentPage" :length="totalPages"></v-pagination>
                    </div>
                    <v-btn type="submit" class="button mt-6" variant="tonal" color="primary" @click="exportData()">
                        <Icon width="24" height="24" viewBox="0 0 24 24" strockeWidth="2.2" :iconName="'file-plus'" />
                    </v-btn>
                </div>
            </Block>
            <Block padding="5">
                <div class="grid-row">
                    <form @submit.prevent="filter()">
                        <div>
                            <span>ID товара:</span>
                            <v-text-field v-model.number="goodsId" autocomplete="off" outlined class="input"></v-text-field>
                        </div>
                        <div>
                            <SelectBlock v-model="field" :items="findFields" placeholder="Искать по">
                            </SelectBlock>
                        </div>
                        <v-checkbox color="#629F33" :label="'только новые'" v-model="onlyNew"></v-checkbox>
                        <v-btn type="submit" class="button mt-6" variant="tonal" color="primary">
                            Найти
                        </v-btn>
                    </form>
                    <v-btn type="submit" class="button mt-6" variant="tonal" color="primary"
                        @click="showModal('importCommissions')">
                        Импортировать
                    </v-btn>
                </div>
            </Block>
            <Block :style="{ height: '85vh' }">
                <!-- <TableBlock v-infinite-scroll="loadMoreItems" infinite-scroll-disabled="isLoading" -->
                <TableBlock>
                    <template v-slot:head>
                        <tr>
                            <th>MphoneID</th>
                            <th>MerlionID</th>
                            <th>Название</th>
                            <th>WB</th>
                            <th>OZON</th>
                            <th>Yandex</th>
                            <th>Sber</th>
                        </tr>
                    </template>
                    <template v-slot:body>
                        <tr v-for="g of goodsList" :key="'g-' + g.id" :class="{ newline: isNew(g) }">
                            <td>{{ g.id }}</td>
                            <td>{{ g.merlionID || '—' }}</td>
                            <td>{{ g.name || g.merlionName || '' }}</td>
                            <td>
                                <v-text-field v-model.number="g.wb" autocomplete="off" outlined class="input"
                                    @change="changeGoods($event, g.id, 'wb')"></v-text-field>
                            </td>
                            <td>
                                <v-text-field v-model.number="g.ozon" autocomplete="off" outlined class="input"
                                    @change="changeGoods($event, g.id, 'ozon')"></v-text-field>
                            </td>
                            <td>
                                <v-text-field v-model.number="g.yandex" autocomplete="off" outlined class="input"
                                    @change="changeGoods($event, g.id, 'yandex')"></v-text-field>
                            </td>
                            <td>
                                <v-text-field v-model.number="g.sber" autocomplete="off" outlined class="input"
                                    @change="changeGoods($event, g.id, 'sber')"></v-text-field>
                            </td>
                        </tr>
                    </template>
                </TableBlock>
            </Block>
        </Grid>
        <Modal ref="importCommissions" :notFooterBorder="true" padding="20" :hideHeader="true" size="medium">
            <template v-slot:body>
                <div class="import-dialog">
                    <Grid :column="2" :gap="20" :customStyle="{ alignItems: 'center' }">
                        <span>ID товара:</span>
                        <v-text-field v-model="importData.id" autocomplete="off" outlined class="input"></v-text-field>
                        <span>Сбер:</span>
                        <v-text-field v-model="importData.sber" autocomplete="off" outlined class="input"></v-text-field>
                        <span>Озон:</span>
                        <v-text-field v-model="importData.ozon" autocomplete="off" outlined class="input"></v-text-field>
                        <span>Yandex:</span>
                        <v-text-field v-model="importData.yandex" autocomplete="off" outlined class="input"></v-text-field>
                        <span>WB:</span>
                        <v-text-field v-model="importData.wb" autocomplete="off" outlined class="input"></v-text-field>
                        <div>
                            <input v-show="false" type="file" ref="file" accept=".xlsx" @change="onFileSelected" />
                            <v-btn variant="outlined" class="btn-w-border" @click="$refs['file'].click()">
                                <template v-if="importData.file?.name">
                                    {{ importData.file.name }}
                                </template>
                                <template v-else>
                                    Выберите файл
                                    <Icon width="24" height="24" viewBox="0 0 24 24" strockeWidth="2"
                                        :iconName="'attach'" />
                                </template>
                            </v-btn>
                        </div>
                        <div>
                            <v-btn type="submit" class="button mt-6" variant="tonal" color="primary" block
                                @click="importCommissions()">
                                Импортировать
                            </v-btn>
                        </div>
                    </Grid>
                </div>
            </template>
        </Modal>
    </div>
</template>
<script>
import { mapState } from 'vuex'
// import { actionTypes } from '@/store/modules/auth'
import { globalMethods } from '@/mixins/globalMethods'
import InfiniteScroll from 'vue-infinite-scroll';

export default {
    name: 'LogisticsPage',
    // components: {
    // },
    mixins: [globalMethods],
    directives: {
        InfiniteScroll
    },
    computed: {
    },
    created() {
        if (!this.checkAccess(this.$route.name)) {
            this.$router.push({ name: 'Main' });
        }
    },
    data() {
        return {
            findFields: [
                { label: 'MphoneID', value: 'id' },
                { label: 'MerlionID', value: 'merlionID' },
            ],
            field: 'id',
            totalPages: 0,
            currentPage: 1,
            goodsId: null,
            page: 0,
            gIndex: 100,
            goodsList: [],
            goodsTotalList: [],
            items: [],
            isLoading: false,
            importData: {
                file: null,
                id: 'A',
                sber: 'B',
                ozon: 'C',
                yandex: 'D',
                wb: 'E',
            },
            onlyNew: false,
        }
    },
    mounted() {
        this.filter()
    },
    watch: {
        'onlyNew': function (val) {
            this.currentPage = 1
        },
        'importData.id': function (val) {
            if (/^[a-z]+$/i.test(val)) {
                this.$set(this.importData, 'id', val.toUpperCase())
            } else {
                this.$set(this.importData, 'id', 'A')
            }
        },
        'importData.sber': function (val) {
            if (/^[a-z]+$/i.test(val)) {
                this.$set(this.importData, 'sber', val.toUpperCase())
            } else {
                this.$set(this.importData, 'sber', 'B')
            }
        },
        'importData.yandex': function (val) {
            if (/^[a-z]+$/i.test(val)) {
                this.$set(this.importData, 'yandex', val.toUpperCase())
            } else {
                this.$set(this.importData, 'yandex', 'C')
            }
        },
        'importData.ozon': function (val) {
            if (/^[a-z]+$/i.test(val)) {
                this.$set(this.importData, 'ozon', val.toUpperCase())
            } else {
                this.$set(this.importData, 'ozon', 'D')
            }
        },
        'importData.wb': function (val) {
            if (/^[a-z]+$/i.test(val)) {
                this.$set(this.importData, 'wb', val.toUpperCase())
            } else {
                this.$set(this.importData, 'wb', 'E')
            }
        },
        currentPage: function () {
            this.filter()
        },
    },
    methods: {
        exportData() {
            this.sendRequest('GET', { tb: 'goods_commissions' }, '/export_tb_to_excel', {}, true, true)
                .then(response => {
                    location.href = "https://monitor.dambul-shop.ru/api/open/" + response.file;
                })
                .catch(err => {
                    this.$store.commit('setError', err)
                })
        },
        isNew(row) {
            if (
                row.sber == 30
                && row.ozon == 30
                && row.wb == 30
                && row.yandex == 30
            ) {
                return true
            } else {
                return false
            }
        },
        onFileSelected(event) {
            this.$set(this.importData, 'file', this.$refs.file.files[0])
        },
        importCommissions() {
            if (
                this.importData.id &&
                this.importData.sber &&
                this.importData.yandex &&
                this.importData.wb &&
                this.importData.ozon
            ) {
                let file = this.importData.file;

                let formData = new FormData();
                formData.append("file", file);

                formData.append("id", this.importData.id);
                formData.append("sber", this.importData.sber);
                formData.append("ozon", this.importData.ozon);
                formData.append("yandex", this.importData.yandex);
                formData.append("wb", this.importData.wb);
                formData.append("type", 'commissions');
                // this.sendRequest('POST', formData, '/parsing_file', {
                this.sendRequest('POST', formData, '/import_data', {
                    "content-type": "multipart/form-data"
                }, true, true)
                    .then(response => {
                        this.importData.file = null
                        this.filter()
                        this.$refs['importCommissions']._cancel()
                    })
                    .catch(err => {
                        this.$store.commit('setError', err)
                    })
            }
        },
        changePage(action) {
            // {{currentPage}} из {{ goodsTotalList.length }}
            if (action == 'next') {
                let nextPage = this.page + 1
                if (nextPage <= this.goodsTotalList.length) {
                    this.$set(this, 'page', nextPage)
                } else {
                    this.$set(this, 'page', this.goodsTotalList.length)
                }
            } else {
                let prevPage = this.page - 1
                if (prevPage > 0) {
                    this.$set(this, 'page', prevPage)
                } else {
                    this.$set(this, 'page', 0)
                }
            }
            this.$set(this, 'goodsList', this.goodsTotalList[this.page])
        },
        arrayChunk(array, size) {
            const chunks = [];
            let index = 0;
            while (index < array.length) {
                chunks.push(array.slice(index, index + size));
                index += size;
            }
            return chunks;
        },
        loadMoreItems() {
            if (!this.isLoading) {
                this.isLoading = true;

                // Симуляция загрузки данных (замените эту часть на фактический код загрузки данных)
                setTimeout(() => {
                    this.$set(this, 'goodsList', [...this.goodsList, ...this.items.splice(this.gIndex, 100)])
                    this.$set(this, 'gIndex', this.gIndex + 100)
                    this.isLoading = false;
                }, 1000);
            }
        },
        filter() {
            let data = {
                page: this.currentPage - 1,
            }
            if (this.onlyNew) {
                data.only_new = true;
            }
            if (this.goodsId) {
                data.page = 0;
                data.field = this.field
                data.goods_id = this.goodsId
                this.currentPage = 1
            }
            this.sendRequest('GET', data, '/get_commissions', {}, true, true)
                .then(response => {
                    // this.$set(this, 'pageCount', Math.ceil(response.len / 100))
                    // this.$set(this, 'items', response)

                    let arr1 = [],
                        arr2 = []
                    response.items?.map(r => {
                        if (
                            r.wb == 30 &&
                            r.ozon == 30 &&
                            r.sber == 30 &&
                            r.yandex == 30
                        ) {
                            arr1.push(r)
                        } else {
                            arr2.push(r)
                        }
                    })
                    // let chunk = this.arrayChunk([...arr1, ...arr2], 100)
                    // this.$set(this, 'goodsTotalList', chunk)
                    // this.$set(this, 'goodsList', chunk[0])
                    this.$set(this, 'totalPages', Math.ceil(response.count / 100))
                    this.$set(this, 'goodsList', [...arr1, ...arr2])

                })
                .catch(err => {
                    this.$store.commit('setError', err)
                })
        },
        changeGoods(value, id, items) {
            this.sendRequest('PUT', { value, id, items }, '/change_commissions', {}, true, true)
                .then(response => {
                    // this.$set(this, 'goodsList', response)
                })
                .catch(err => {
                    this.$store.commit('setError', err)
                })
        },
    }
}
</script>
<style lang="scss">
#logistic-page {
    table {
        height: 100%;

        thead {
            position: sticky;
            top: 0;
            z-index: 3;
        }
    }

    .table-contoll {
        overflow: unset;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 5px;
        height: 100% !important;
    }

    .pag {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .grid-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;

        >div {
            // flex: 1;
        }

        form {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 5px;
            align-items: flex-end;

            @media screen and (max-width: 576px) {
                grid-template-columns: 100%;
            }
        }
    }

    .newline {
        td {
            background: #beffc8 !important;
        }
    }
}
</style>
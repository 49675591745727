<template>
    <div id="merlion-page">
        <Grid :column="1" :gap="20" :gridStyle="{ maxWidth: '100%' }">

            <Block padding="5">
                <Grid :column="3" :gap="5">
                    <v-btn class="button mt-6" variant="tonal" color="primary" @click="showModal('catalog')">
                        Выбрать категории
                    </v-btn>
                    <SelectBlock v-model="filter.shipment_method" :items="shipmentMethodList"
                        placeholder="Код метода отгрузки">
                    </SelectBlock>
                    <SelectBlock v-model="filter.shipment_date" :items="shipmentDatesList" placeholder="Дата отгрузки">
                    </SelectBlock>
                    <SelectBlock v-model="filter.only_avail" :items="onlyAvail" placeholder="Доступности товаров">
                    </SelectBlock>
                    <div>
                        <v-text-field v-model.number="filter.max_price" autocomplete="off" outlined class="input"
                            placeholder="Цена до"></v-text-field>
                    </div>
                    <v-btn class="button mt-6" variant="tonal" color="primary" @click="find()">
                        Найти
                    </v-btn>
                    <v-btn class="button mt-6" variant="tonal" color="primary" @click="reload()">
                        Обновить данные товаров
                    </v-btn>
                </Grid>
            </Block>

            <Grid :column="1" :gap="5">
                <h2>Выбранные категории</h2>
                <Block :padding="filter.cat_id.length > 0 ? 10 : 0">
                    <div class="selected-cat">
                        <span v-for="item in selectedCat" :key="item.id">{{ item.name }}</span>
                    </div>
                </Block>
            </Grid>
            <template v-if="goodsTotalList.length > 0">
                <Block padding="5">
                    <div class="pag">
                        <div>страница: {{ currentPage }} из {{ goodsTotalList.length }}</div>
                        <div>
                            <v-btn variant="outlined" class="btn-w-border" @click="changePage('prev')">
                                <Icon width="24" height="24" viewBox="0 0 24 24" strockeWidth="2.2"
                                    :iconName="'chevron-left'" />
                            </v-btn>
                            <v-btn variant="outlined" class="btn-w-border" @click="changePage('next')">
                                <Icon width="24" height="24" viewBox="0 0 24 24" strockeWidth="2.2"
                                    :iconName="'chevron-right'" />
                            </v-btn>
                        </div>
                    </div>
                </Block>
                <Block :style="{ height: '60vh' }">
                    <TableBlock>
                        <template v-slot:head>
                            <tr>
                                <th></th>
                                <th>#</th>
                                <th>цена клиента (USD)</th>
                                <th>цена клиента для региона</th>
                                <th>цена клиента для московского региона</th>
                                <th>доступное количество товара</th>
                                <th>доступное количество товара на региональном складе</th>
                                <th>доступное количество товара на московском складе</th>
                                <th>ожидаемый приход товара</th>
                                <th>следующий приход товара</th>
                                <th>дата следующего прихода товара</th>
                                <th>рекомендованная розничная цена (РРЦ)</th>
                                <th>дата начала действия РРЦ</th>
                                <th>цена клиента (RUB)</th>
                                <th>цена клиента для региона</th>
                                <th>цена клиента для московского региона</th>
                                <th>признак «он-лайн резервирования»</th>
                                <th>стоимость отмены резерва</th>
                            </tr>
                            <tr>
                                <th>
                                    <v-btn class="button mt-6" variant="tonal" color="primary" @click="exportData()">
                                        <Icon width="24" height="24" viewBox="0 0 24 24" strockeWidth="2.2"
                                            :iconName="'file-plus'" />
                                    </v-btn>
                                </th>
                                <th></th>
                                <th v-for="key of Object.keys(itemsToFile)" :key="key">
                                    <v-checkbox color="#629F33" v-model="forA" :value="key"></v-checkbox>
                                    <!-- <SelectBlock v-model="itemsToFile[key]" :items="columnList"></SelectBlock> -->
                                    <!-- <v-text-field v-model="itemsToFile[key]" autocomplete="off" outlined
                                        class="input"></v-text-field> -->
                                </th>
                            </tr>
                        </template>
                        <template v-slot:body>
                            <tr v-for="g of goodsList" :key="'g-' + g.No">
                                <td>
                                    <!-- <v-checkbox color="#629F33" :value="g.No"></v-checkbox> -->
                                </td>
                                <td>{{ g.No }}</td>
                                <td>{{ g.PriceClient }}</td>
                                <td>{{ g.PriceClient_RG }}</td>
                                <td>{{ g.PriceClient_MSK }}</td>
                                <td>{{ g.AvailableClient }}</td>
                                <td>{{ g.AvailableClient_RG }}</td>
                                <td>{{ g.AvailableClient_MSK }}</td>
                                <td>{{ g.AvailableExpected }}</td>
                                <td>{{ g.AvailableExpectedNext }}</td>
                                <td>{{ g.DateExpectedNext }}</td>
                                <td>{{ g.RRP }}</td>
                                <td>{{ g.RRP_Date }}</td>
                                <td>{{ g.PriceClientRUB }}</td>
                                <td>{{ g.PriceClientRUB_RG }}</td>
                                <td>{{ g.PriceClientRUB_MSK }}</td>
                                <td>{{ g.Online_Reserve }}</td>
                                <td>{{ g.ReserveCost }}</td>
                            </tr>
                        </template>
                    </TableBlock>
                </Block>
            </template>
        </Grid>
        <Modal ref="catalog" padding="20" size="medium">
            <template v-slot:header>
                Каталог
            </template>
            <template v-slot:body>
                <TreeCheckbox :level="0" :items="catalogTree" :selectedItem="selectedItem" :dropItem="dropItem"
                    :select="filter.cat_id">
                </TreeCheckbox>
            </template>
            <template v-slot:footer>
                <v-btn class="button mt-6" variant="tonal" color="primary" @click="followUpdate()">
                    Отслеживать обновления
                </v-btn>
            </template>
        </Modal>
    </div>
</template>
<script>
import { mapState } from 'vuex'
// import { actionTypes } from '@/store/modules/auth'
import { globalMethods } from '@/mixins/globalMethods'
import InfiniteScroll from 'vue-infinite-scroll';


export default {
    name: 'MerlionPage',
    // components: {
    // },
    mixins: [globalMethods],
    directives: {
        InfiniteScroll
    },
    created() {
        if (!this.checkAccess('drop')) {
            this.$router.push({ name: 'Main' });
        }
    },
    computed: {
        currentPage() {
            return this.page + 1
        },
        shipmentMethodList: function () {
            return this.shipmentMethods.map(i => {
                return { label: i.Description, value: i.Code }
            })
        },
        columnList: function () {
            const columns = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'R']
            return columns.map(i => {
                return { label: i, value: i }
            })
        },
        shipmentDatesList: function () {
            return this.shipmentDates.map(i => {
                return { label: i.Date, value: i.Date }
            })
        }
    },
    data() {
        return {
            clearData: [],
            forA: ['No', 'PriceClient', 'PriceClient_MSK', 'PriceClientRUB_MSK', 'AvailableClient_MSK'],
            itemsToFile: {
                PriceClient: 'B',
                PriceClient_RG: 'C',
                PriceClient_MSK: 'D',
                AvailableClient: 'E',
                AvailableClient_RG: 'F',
                AvailableClient_MSK: 'G',
                AvailableExpected: 'H',
                AvailableExpectedNext: 'I',
                DateExpectedNext: 'J',
                RRP: 'K',
                RRP_Date: 'L',
                PriceClientRUB: 'M',
                PriceClientRUB_RG: 'N',
                PriceClientRUB_MSK: 'O',
                Online_Reserve: 'P',
                ReserveCost: 'R',
            },
            page: 0,
            goodsList: [],
            goodsTotalList: [],
            shipmentMethods: [],
            shipmentDates: [],
            onlyAvail: [
                { label: 'все активные товары', value: 0 },
                { label: 'только доступные для резервирования товары', value: 1 },
            ],
            goods: [],
            filter: {
                shipment_method: null,
                shipment_date: null,
                only_avail: null,
                cat_id: []
            },
            selectedCat: {},
            catalogTree: []
        }
    },
    mounted() {
        Promise.allSettled([
            this.sendRequest('GET', {}, '/merlion/get_shipment_dates', {}, true, true),
            this.sendRequest('GET', {}, '/merlion/get_shipment_methods', {}, true, true),
            this.sendRequest('GET', {}, '/merlion/get_catalog', {}, true, true),
        ]).then(([dates, methods, catalog]) => {
            if (dates.status == 'fulfilled') {
                this.shipmentDates = dates.value
            }
            if (methods.status == 'fulfilled') {
                this.shipmentMethods = methods.value
                let defaultItem = methods.value.find(i => i.IsDefault == 1)
                this.filter.shipment_method = defaultItem.Code
            }
            if (catalog.status == 'fulfilled') {
                this.catalogTree = catalog.value
            }
        })
    },
    watch: {

    },
    methods: {
        reload() {

            this.sendRequest('GET', {}, '/merlion/reload_total', {}, true, true)
                .then(response => {
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                })
        },
        followUpdate() {
            if (this.filter.cat_id.length <= 0) {
                return this.$store.commit('setError', 'Укажите категории')
            }
            this.sendRequest('POST', { categories: this.filter.cat_id }, '/merlion/follow_update', {}, true, true)
                .then(response => {
                    // this.$set(this, 'pageCount', Math.ceil(response.len / 100))                    
                    let chunk = this.arrayChunk(response, 100)
                    this.$set(this, 'goodsTotalList', chunk)
                    this.$set(this, 'goodsList', chunk[0])

                })
                .catch(err => {
                    this.$store.commit('setError', err)
                })
        },
        arrayChunk(array, size) {
            const chunks = [];
            let index = 0;
            while (index < array.length) {
                chunks.push(array.slice(index, index + size));
                index += size;
            }
            return chunks;
        },
        selectedItem(item) {
            this.$set(this, 'selectedCat', { ...this.selectedCat, [item.id]: item })
            this.$set(this.filter, 'cat_id', [...this.filter.cat_id, item.id])
        },
        dropItem(id) {
            this.$delete(this.selectedCat, id)
            this.$delete(this.filter.cat_id, this.filter.cat_id.indexOf(id))
        },
        changePage(action) {
            // {{currentPage}} из {{ goodsTotalList.length }}
            if (action == 'next') {
                let nextPage = this.page + 1
                if (nextPage <= this.goodsTotalList.length) {
                    this.$set(this, 'page', nextPage)
                } else {
                    this.$set(this, 'page', this.goodsTotalList.length)
                }
            } else {
                let prevPage = this.page - 1
                if (prevPage > 0) {
                    this.$set(this, 'page', prevPage)
                } else {
                    this.$set(this, 'page', 0)
                }
            }
            this.$set(this, 'goodsList', this.goodsTotalList[this.page])
        },
        find() {
            if (this.filter.cat_id.length <= 0) {
                return this.$store.commit('setError', 'Укажите категории')
            }
            if (
                this.filter.shipment_method === null
                || this.filter.shipment_date === null
                || this.filter.only_avail === null
            ) {
                return this.$store.commit('setError', 'Заполните все поля')
            }

            this.sendRequest('POST', this.filter, '/merlion/get_items_avail', {}, true, true)
                .then(response => {
                    if (response.length == 0) {
                        alert('Товаров нет')
                    }
                    // this.$set(this, 'pageCount', Math.ceil(response.len / 100))
                    // this.$set(this, 'items', response)
                    this.$set(this, 'clearData', response)
                    let chunk = this.arrayChunk(response, 100)
                    this.$set(this, 'goodsTotalList', chunk)
                    this.$set(this, 'goodsList', chunk[0])

                })
                .catch(err => {
                    this.$store.commit('setError', err)
                })
        },
        exportData() {
            // this.sendRequest('POST', { fields: this.itemsToFile, items: this.goodsList }, '/merlion/export_to_excel', {}, true, true)
            let forExport = this.clearData.map(g => {
                let obj = {}
                this.forA.forEach(element => {
                    obj[element] = g[element]
                });
                return obj
            })

            this.sendRequest('POST', { fields: this.forA, items: forExport }, '/merlion/export_to_excel', {}, true, true)
                .then(response => {
                    location.href = "https://monitor.dambul-shop.ru/api/open/" + response.file;
                })
                .catch(err => {
                    this.$store.commit('setError', err)
                })
        }
    }
}
</script>
<style lang="scss">
#merlion-page {
    table {
        height: 100%;

        thead {
            position: sticky;
            top: 0;
            z-index: 3;

            th {
                white-space: unset;
            }
        }
    }

    .table-contoll {
        overflow: unset;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 5px;
        height: 100% !important;
    }

    .pag {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .selected-cat {
        span {
            &:not(:last-child) {
                &::after {
                    content: ", ";
                }
            }
        }
    }
}
</style>
<template>
    <div id="arithmetic-page">
        <Grid :column="1" :gap="30">
            <Grid :column="1" :gap="30">
                <Block :style="{ overflow: 'hidden' }" padding="10">
                    <Grid :column="8" :gap="10" :customStyle="{ alignItems: 'flex-end' }">
                        <div v-for="(value, ammount, ind) of profitList" :key="ind">
                            <div>
                                <b>{{ ammount }} — {{ Object.keys(profitList)[ind + 1] - 1 }}</b>
                            </div>
                            <v-text-field v-model.number="profitList[ammount]" autocomplete="off" outlined
                                class="input"></v-text-field>
                        </div>
                    </Grid>
                </Block>
            </Grid>
            <Block padding="10">
                <ul class="info">
                    <li><b>{logistika}</b> - Логистика Сбера</li>
                    <li><b>{logistika_wb}</b> - Логистика WB</li>
                    <li><b>{logistika_ozon}</b> - Логистика Ozon</li>
                    <li><b>{logistika_yandex}</b> - Логистика Yandex</li>
                    <li><b>{zakup}</b> - Закупочная цена</li>
                    <li><b>{profit}</b> - Тег профита</li>
                    <li><b>{wblog}</b> - Логистика ВБ</li>
                    <li><b>{perexodnik}</b> - Переходник</li>
                    <li><b>{название поля}</b> - значение из поля</li>
                    <li><b>Пример формулы</b>: {zakup} + 150 + ((( {zakup} + 150) / 100) *( {procent_aliexpress}
                        + 9))</li>
                </ul>
            </Block>
            <Grid :column="1" :gap="10">
                <Block padding="20" v-for="mpName of mp" :key="mpName">
                    <Grid :column="3" :gap="30" :customStyle="gridLine">

                        <b>{{ mpName }}</b>
                        <v-text-field v-model.number="form[mpName]" autocomplete="off" outlined class="input"
                            placeholder="Формула"></v-text-field>
                        <v-btn type="submit" class="button mt-6" variant="tonal" color="primary" @click="save(mpName)">
                            Сохранить
                        </v-btn>

                    </Grid>
                </Block>
            </Grid>
        </Grid>
    </div>
</template>
<script>
import { mapState } from 'vuex'
// import { actionTypes } from '@/store/modules/auth'
import { globalMethods } from '@/mixins/globalMethods'
import { resolve } from 'path'
import Block from '@/components/Block.vue'

export default {
    components: { Block },
    name: 'arithmeticPage',
    // components: {
    // },
    mixins: [globalMethods],
    data() {
        return {
            profitList: {},
            mp: [
                'ozon',
                'yandex',
                'wb',
                'sber',
            ],
            form: {
                'ozon': '',
                'yandex': '',
                'wb': '',
                'sber': '',
            }


        }
    },
    created() {
        if (!this.checkAccess('drop')) {
            this.$router.push({ name: 'Main' });
        }
    },
    mounted() {
        this.sendRequest('GET', { for: 'ds' }, '/arithmetic_forlmules', {}, true, true)
            .then(response => {
                this.form = response
            })
            .catch(err => {
                this.$store.commit('setError', err)
            })
        this.sendRequest('GET', { name: 'profit_ds' }, '/get_options', {}, true, true)
            .then(response => {
                this.profitList = response
            })
            .catch(err => {
                this.$store.commit('setError', err)
            })
        // const profitList = {
        //     "1": 100,
        //     "101": 200,
        //     "201": 400,
        //     "401": 600,
        //     "601": 900,
        //     "901": 1300,
        //     "1301": 1700,
        //     "1701": 2500,
        //     "2501": 3500,
        //     "3501": 5000,
        //     "5001": 7500,
        //     "7501": 10000,
        //     "10001": 15000,
        //     "15001": 20000,
        //     "20001": 25000,
        //     "25001": 30000,
        //     "30001": 35000,
        //     "35001": 40000,
        //     "40001": 45000,
        //     "45001": 50000,
        //     "50001": 70000,
        //     "70001": 90000,
        //     "90001": 110000,
        //     "110001": 150000
        // };

        // const numberToFind = 8456;

        // let foundRange = null;
        // let foundValue = null;

        // for (const start in profitList) {
        //     if (numberToFind >= Number(start) && numberToFind <= profitList[start]) {
        //         foundRange = `${start} - ${profitList[start]}`;
        //         foundValue = profitList[start]
        //         break;
        //     }
        // }

        // if (foundRange) {
        //     console.log(`Число ${numberToFind} находится в диапазоне ${foundRange} значени ${foundValue}`);
        // } else {
        //     console.log(`Число ${numberToFind} не входит в ни один из диапазонов`);
        // }
    },
    watch: {
        profitList: {
            handler(newVal) {
                this.sendRequest('PUT', { name: 'profit_ds', data: newVal }, '/set_option', {}, true, true)
            },
            deep: true // Указываем, что хотим следить за изменениями вложенных свойств
        },
    },
    destroyed() {

    },
    computed: {
        ...mapState({
            user: state => state.user,
        }),
        isMobyle() {
            return this.windowWidth <= 560
        },
        gridLine() {
            return { gridTemplateColumns: '100px 1fr 150px', alignItems: 'center' }
        }
    },
    methods: {
        save(mp) {
            if (!this.form[mp]) {
                this.$store.commit('setError', 'Укажите формулу')
            } else {
                this.sendRequest('POST', { mp, formula: this.form[mp], for: 'ds' }, '/save_arithmetic', {}, true, true)
                    .then(response => { })
                    .catch(err => {
                        this.$store.commit('setError', err)
                    })
            }
        }
    }
}
</script>
<style lang="scss">
#drop-page {
    .info {
        padding-left: 20px;
    }
}
</style>
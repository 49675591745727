<template>
    <div id="join-excel-page">
        <Grid :column="1" :gap="20">
            <!-- :style="{ overflow: 'hidden' }" -->
            <Block>
                <Grid :column="6" :gap="5" :style="{ alignItems: 'center', padding: '5px' }">
                    <div>
                        <input v-show="false" type="file" ref="file" accept=".xlsx" @change="onFileSelected" multiple />
                        <v-btn variant="outlined" class="btn-w-border" @click="$refs['file'].click()">
                            <template v-if="specForm.file?.name">
                                {{ specForm.file.name }}
                            </template>
                            <template v-else>
                                Выберите файл
                                <Icon width="24" height="24" viewBox="0 0 24 24" strockeWidth="2" :iconName="'attach'" />
                            </template>
                        </v-btn>
                    </div>
                    <v-btn type="submit" class="button mt-6" variant="tonal" color="primary" @click="parsing()">
                        Распарсить
                    </v-btn>
                </Grid>
            </Block>
        </Grid>
    </div>
</template>
<script>
import { mapState } from 'vuex'
// import { actionTypes } from '@/store/modules/auth'
import { globalMethods } from '@/mixins/globalMethods'

export default {
    name: 'JoinExcelPage',
    // components: {
    // },
    mixins: [globalMethods],
    computed: {
    },
    data() {
        return {
            yes: '',
            selectItems: [],
            parseData: null,
            specForm: {
                file: null,
                article: 'A',
                price: 'B',
                compensation: 0,
                logistic: 0
            },
            mpSelect: 'Wb',
            listSelect: null,
            date: ''
        }
    },
    watch: {
        'yes': function (val) {
            if (/^[a-z]+$/i.test(val)) {
                this.$set(this.specForm, 'price', val.toUpperCase())
            } else {
                this.$set(this.specForm, 'price', 'Z')
            }
        },
        'specForm.price': function (val) {
            if (/^[a-z]+$/i.test(val)) {
                this.$set(this.specForm, 'price', val.toUpperCase())
            } else {
                this.$set(this.specForm, 'price', 'B')
            }
        },
        'specForm.article': function (val) {
            if (/^[a-z]+$/i.test(val)) {
                this.$set(this.specForm, 'article', val.toUpperCase())
            } else {
                this.$set(this.specForm, 'article', 'A')
            }
        },
    },
    methods: {
        setPrice() {
            let items = this.parseData.reduce((result, item) => {
                if (this.selectItems.includes(item.id)) {
                    return [...result, { id: item.id, price: item.price }]
                } else {
                    return result
                }
            }, [])

            let file = this.specForm.file;

            let formData = new FormData();
            formData.append("file", file);
            formData.append("items", JSON.stringify(items));
            formData.append("yes", this.yes);
            formData.append("article", this.specForm.article);

            formData.append("date", this.$moment(this.date).format('YYYY-MM-DD_HH:mm'));
            formData.append("mp", this.mpSelect);
            formData.append("list", this.listSelect || 0);

            this.sendRequest('POST', formData, '/set_price', {
                "content-type": "multipart/form-data",
            }, true, true)
                .then(response => {
                    // axios.defaults.baseURL = process.env.NODE_ENV !== 'production' ? 'http://localhost:3301' : 'http://monitor.dambul-shop.ru'
                    location.href = "https://monitor.dambul-shop.ru/api/open/" + response.file;
                })
                .catch(err => {
                    this.$store.commit('setError', err)
                })
            // this.sendRequest('POST', { date: this.$moment(this.date).format('YYYY-MM-DD_HH:mm'), mp: this.mpSelect, items }, '/set_price', {}, true, true)
            //     .then(response => {
            //         alert('цены установлены')
            //     })
            //     .catch(err => {
            //         this.$store.commit('setError', err)
            //     })

        },
        selectAll(check) {
            if (check == true) {
                this.$set(this, 'selectItems', this.parseData.reduce((result, item) => {
                    return [...result, '' + item.id]
                }, []))
            } else {
                this.$set(this, 'selectItems', [])
            }
        },
        disabledTimeRange(date) {
            return this.$moment(date).unix() < Math.floor(new Date().setDate(new Date().getDate()) / 1000)
        },
        disabledRange(date) {
            return date < new Date(new Date().setDate(new Date().getDate() - 1))
        },
        getCustomDay(date) {
            const daysOfWeek = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];
            let i = new Date(date).getDay(date)
            return daysOfWeek[i]
        },
        onFileSelected(event) {
            this.$set(this.specForm, 'file', this.$refs.file.files)
        },
        getClass(n) {
            if (n.stock <= 0) {
                return 'blue'
            } else {
                return n[`pp${this.mpSelect}`] < 0 ? 'red' : 'green'
            }
        },
        parsing() {
            if (this.specForm.file) {
                let formData = new FormData();

                for (let i = 0; i < this.specForm.file.length; i++) {
                    const file = this.specForm.file[i];
                    formData.append('files', file);
                }

                this.sendRequest('POST', formData, '/join_excel', {
                    "content-type": "multipart/form-data"
                }, true, true)
                    .then(response => {
                        location.href = "https://monitor.dambul-shop.ru/api/open/" + response.file;
                    })
                    .catch(err => {
                        this.$store.commit('setError', err)
                    })
            }
        },
    }
}
</script>
<style lang="scss">
#stocks-page {
    .table-scroll {
        height: 100vh;

        thead {
            position: sticky;
            top: 0;
            z-index: 3;
        }

        tbody {
            tr {
                &.red {
                    td {
                        background: rgba(159, 51, 51, 0.376);
                    }
                }

                &.green {
                    td {
                        background: rgba(98, 159, 51, 0.376);
                    }
                }

                &.blue {
                    td {
                        background: rgba(51, 114, 159, 0.376);
                    }
                }
            }
        }
    }

    .btn-w-border {
        max-width: 200px !important;
        overflow: hidden;
    }
}
</style>
<template>
    <div class="login-form">
        <span class="caption">Вход в систему</span>
        <v-form @submit.prevent="onSubmit" class="form-login">
            <v-text-field v-model="login" autocomplete="off" outlined class="input" placeholder="Email"
                :error-messages="errors.login" :error="!!errors.login"></v-text-field>

            <v-text-field v-model="password" :append-icon="value ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                @click:append="() => (value = !value)" :type="value ? 'password' : 'text'" outlined placeholder="Пароль"
                color="secondary" class="input password-input" :error-messages="errors.password" :error="!!errors.password">
            </v-text-field>

            <v-btn type="submit" class="button mt-6" variant="tonal" color="primary" block :disabled="buttonDisabled">
                Войти
            </v-btn>
        </v-form>
        <div class="remember-me" @click="showModal('forgot')">
            <span>Забыли пароль?</span>
        </div>
        <Modal ref="forgot" :notFooterBorder="true" padding="57-94" :hideHeader="true" size="small">
            <template v-slot:body>
                <div class="forgot">
                    <div class="heading">
                        Забыли пароль?
                    </div>
                    <div class="message">
                        Для востановления пароля обратитесь
                        к администратору или непосредственному руководителю
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>
<script>
import { mapState } from 'vuex'
// import { actionTypes } from '@/store/modules/auth'
import { globalMethods } from '@/mixins/globalMethods'
export default {
    name: 'LoginPage',
    // components: {
    // },
    mixins: [globalMethods],
    data() {
        return {
            login: '',
            buttonDisabled: false,
            errors: {
                login: null,
                password: null,
            },
            password: '',
            remember: false,
            value: String,
            dialog: false
        }
    },
    computed: {
        ...mapState({
            //   isSubmitting: state => state.auth.isSubmitting,
            //   validationErrors: state => state.auth.validationErrors
        })
    },
    mounted() {    
        if (this.$cookies.get('token')) {            
            this.$router.push({ name: 'Main' });
        }
    },
    watch: {

    },
    methods: {
        onSubmit() {
            this.$set(this, 'buttonDisabled', true)
            let obj = {
                email: this.login,
                password: this.password
            }
            this.sendRequest('POST', obj, '/login', {}, true, true)
                .then(response => {                    
                    this.$set(this, 'buttonDisabled', false)
                    this.$cookies.set("token", response.token, {
                        path: "/",
                        maxAge: this.$moment().unix() + 60 * 60 * 12,
                    });
                    this.$router.push({ name: 'Main' });
                })
                .catch(err => {
                    this.$set(this, 'errors', {
                        login: 'Неверный email',
                        password: 'Неверный пароль',
                    })
                    this.$set(this, 'buttonDisabled', false)
                    // this.$store.commit('setError', err)
                })
            // this.$store
            //     .dispatch(actionTypes.login, {
            //         login: this.login,
            //         password: this.password
            //     })
            //     .then((r) => {
            //         router.push({ path: '/main' });
            //     })
            //     .catch(err => {

            //     })
        }
    }
}
</script>
<style lang="scss">
.login-form {
    width: 288px;

    .caption {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #1A1A1A;
        display: block;
        margin-bottom: 20px;
        text-align: center;
    }

    .remember-me {
        margin-top: 30px;
        text-align: center;

        span {
            color: #629F33;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .forgot {
        .heading {
            font-family: 'Circe';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            text-align: center;

            color: #000000;
        }

        .message {
            padding-top: 15px;
            font-family: 'Circe';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            text-align: center;
            color: #1A1A1A;
        }
    }

}
</style>
<template>
  <div id="list-page">
    <template v-if="user?.role == 3">
      <div class="data-controll">
        <Block :style="{ overflow: 'hidden' }">
          <div class="row">
            <div class="checked-div">
              <v-checkbox color="#629F33" label="Выбрать все" @change="selectAll"></v-checkbox>
            </div>
            <div class="btn-div">
              <v-btn class="button mt-6 success" variant="tonal" @click="changeTaskAll()">
                Принять все отмеченные
              </v-btn>
              <!-- <v-btn class="button mt-6" variant="tonal" color="primary"> -->
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-file" @click="generateFile()">
                <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path>
                <polyline points="13 2 13 9 20 9"></polyline>
              </svg>
              <Icon width="20" height="20" viewBox="0 0 24 24" strockeWidth="2.2" iconName="eye"
                @click="showST4 = !showST4" :stroke="showST4 ? '#629f33' : '#282c34'" />
              <!-- </v-btn> -->
            </div>
          </div>
        </Block>
      </div>
    </template>
    <Block :style="{ overflow: 'hidden' }">
      <TableBlock>
        <template v-slot:body>
          <template v-for="(g, ind) of tasksList">
            <tr :key="'g-' + g['_id']" :class="{ 'role-3': user?.role == 3, 'notStock': g?.stock <= 5, fbo: g?.fbo }"
              v-if="showRow(g.status)">
              <td v-if="user?.role == 3">
                <v-checkbox color="#629F33" :id="`checkbox-${g['_id']}`" :ref="`checkbox-${g['_id']}`"
                  v-model="nomenclatureSelect" :value="g['_id']"></v-checkbox>
              </td>
              <td class="bold">{{ $moment(g.date).format('DD.MM HH:mm') }}</td>
              <td>{{ g.goods_id }}</td>
              <td>{{ g.name }}</td>
              <td class="img">
                <template v-if="g.photo">
                  <img :src="`/public/upload/img/${g.photo}`">
                </template>
              </td>
              <td>{{ g.description }}</td>
              <td>
                <div class="ipt">
                  <v-text-field v-model.number="g.count" autocomplete="off" outlined class="input"
                    @change="changeCount($event, g['_id'], g.price, ind)"></v-text-field>
                  шт.
                </div>
              </td>
              <td>
                <template v-if="g.mp">
                  <b>МП:</b>{{ g.mp }}
                </template>
                <template v-else>
                  остаток: {{ g.stock || '—' }}
                </template>
              </td>
              <td>{{ g.price }}₽/шт.</td>
              <td>{{ g.total }}₽</td>
              <td>{{ g.manager }}</td>
              <td>
                <div class="status"
                  :class="{ wait: g.status == 1, process: g.status == 2, 'out-status': g.status == 3, success: g.status == 4 }">
                  <span>
                    <template v-if="g.status == 1">
                      В ожидании
                    </template>
                    <template v-else-if="g.status == 2">
                      Взято в работу
                    </template>
                    <template v-else-if="g.status == 3">
                      Переданно на склад
                    </template>
                    <template v-else-if="g.status == 4">
                      Готово
                    </template>
                  </span>
                </div>

              </td>
              <td class="tg-action-btn">
                <template v-if="user?.role == 2">
                  <v-btn class="button mt-6 process" variant="tonal" block v-if="g.status == 1"
                    @click="changeTask(g['_id'], 2, ind)">
                    <Icon width="16" height="16" viewBox="0 0 16 16" strockeWidth="2" iconName="clock" />
                  </v-btn>
                  <v-btn class="button mt-6 outbtn" variant="tonal" block v-if="g.status == 2"
                    @click="changeTask(g['_id'], 3, ind)">
                    <!-- <Icon width="16" height="16" viewBox="0 0 16 16" strockeWidth="2" iconName="clock" /> -->
                    <Icon width="24" height="24" viewBox="0 0 24 24" strockeWidth="2" iconName="logout" />
                  </v-btn>
                </template>
                <template v-else-if="user?.role == 3">
                  <v-btn class="button mt-6 success" variant="tonal" block v-if="g.status == 3"
                    @click="changeTask(g['_id'], 4, ind)">
                    <Icon width="16" height="16" viewBox="0 0 16 16" strockeWidth="2" iconName="check" />
                  </v-btn>
                </template>
              </td>
            </tr>
          </template>
        </template>
      </TableBlock>
    </Block>
    <Modal ref="print">
      <template v-slot:header>
        Печать этикетки
      </template>
      <template v-slot:footer>
        <Grid :column="3">
          <v-btn class="button" variant="tonal" block @click="print('_wb')">
            WB
          </v-btn>
          <v-btn class="button" variant="tonal" block @click="print('_ozon')">
            Ozon
          </v-btn>
          <v-btn class="button" variant="tonal" block @click="print('')">
            Mphone
          </v-btn>
        </Grid>
      </template>
    </Modal>
  </div>
</template>
<script>
import { mapState } from 'vuex'
// import { actionTypes } from '@/store/modules/auth'
import { globalMethods } from '@/mixins/globalMethods'
import { resolve } from 'path'

export default {
  name: 'listPage',
  // components: {
  // },
  mixins: [globalMethods],
  data() {
    return {
      changeTaskData: {},
      time: null,
      intervalLoadTasks: null,
      tasksList: [],
      nomenclatureSelect: [],
      showST4: false,
      hasLoad: false,
      labels: ''
    }
  },
  created() {
    if (!this.checkAccess(this.$route.name)) {
      this.$router.push({ name: 'Main' });
    }
  },
  mounted() {
    const self = this
    this.loadTask()
    this.$set(this, 'intervalLoadTasks', setInterval(() => {
      new Promise((resolve, reject) => {
        resolve(self.loadTask())
      })
    }, 30000))
  },

  destroyed() {
    window.clearInterval(this.intervalLoadTasks);
  },
  computed: {
    ...mapState({
      user: state => state.user,
    }),
    isMobyle() {
      return this.windowWidth <= 560
    },
  },
  methods: {
    changeCount(value, id, price, ind) {
      const newPrice = parseInt(value) * parseFloat(price)
      const data = {
        count: value,
        total: newPrice,
        id,
      }
      this.sendRequest('PUT', data, '/change_count_task', {}, true, true)
        .then(response => {
          this.$set(this.tasksList[ind], 'total', newPrice)
        })
        .catch(err => {
          this.$store.commit('setError', err)
        })
    },
    showRow(status) {
      if (this.user?.role == 3) {
        if (status == 4) {
          return this.showST4
        } else {
          return true
        }
      } else {
        return true
      }
    },
    changeTaskAll() {
      if (!this.$cookies.get('hasLoad')) {
        this.$store.commit('setError', 'Выгрузите отмеченные задачи')
      } else {
        if (this.nomenclatureSelect.length == 0) {
          this.$store.commit('setError', 'Выбирите хотя бы одну задачу')
        } else {
          this.sendRequest('PUT', { ids: this.nomenclatureSelect }, '/change_tasks', {}, true, true)
            .then(response => {
              this.$cookies.remove("hasLoad");
              this.nomenclatureSelect.map(t => {
                let ind = this.tasksList.findIndex(tt => tt['_id'] == t)
                this.$set(this.tasksList[ind], 'status', 4)
                this.$set(this.tasksList[ind], 'date', this.$moment().format())
                new Promise((resolve, reject) => {
                  this.sendRequest('POST', this.tasksList[ind], '/set_operation', {}, true, true)
                    .then(response => {
                    })
                    .catch(err => {
                      this.$store.commit('setError', err)
                    })
                })
              })
            })
            .catch(err => {
              this.$store.commit('setError', err)
            })
        }
      }
    },
    loadTask() {
      let query = {}//to_day: true
      if (this.user?.role == 3) {
        query.only_status = [3, 4]
      } else if (this.user?.role == 2) {
        query.not_status = [4, 5]
      }
      this.sendRequest('GET', query, '/get_tasks', {}, true, true)
        .then(response => {
          let tasks = response.reverse(),
            fbo = tasks.filter(t => t.fbo),
            notFbo = tasks.filter(t => !t.fbo).sort((a, b) => a.stock - b.stock);

          // if (this.user?.role == 3) {
          //     tasks = tasks.filter(t => t.status == 3 || t.status == 4)
          // }
          this.$set(this, 'tasksList', [...fbo, ...notFbo])
        })
        .catch(err => {
          this.$store.commit('setError', err)
        })
    },
    print(label) {
      let id = this.changeTaskData?.id || null,
        status = this.changeTaskData?.status || null,
        ind = this.changeTaskData?.ind
      new Promise((resolve, reject) => {
        this.sendRequest('PUT', { id, status }, '/change_task', {}, true, true)
          .then(response => {
            this.$set(this.tasksList[ind], 'status', status)
            this.$set(this.tasksList[ind], 'date', this.$moment().format())
            if (status == 4) {
              this.sendRequest('POST', this.tasksList[ind], '/set_operation', {}, true, true)
                .then(response => {

                  this.$set(this.tasksList[ind], 'status', status)
                  this.$set(this.tasksList[ind], 'date', this.$moment().format())

                })
                .catch(err => {
                  this.$store.commit('setError', err)
                })
            }
          })
          .catch(err => {
            this.$store.commit('setError', err)
          })
      })

      const url = this.tasksList[ind][`label${label}`];
      const windowName = 'myWindow';
      const windowFeatures = 'width=600,height=400,toolbar=no,location=no';

      window.open(url, windowName, windowFeatures);
      this.$refs['print']._cancel()
    },
    changeTask(id, status, ind) {
      if (status == 3) {
        this.$set(this, 'changeTaskData', {
          id, status, ind
        })
        this.$refs['print'].show({}).then((result, label) => {
          if (result == true) {

            new Promise((resolve, reject) => {
              this.sendRequest('PUT', { id, status }, '/change_task', {}, true, true)
                .then(response => {
                  this.$set(this.tasksList[ind], 'status', status)
                  this.$set(this.tasksList[ind], 'date', this.$moment().format())
                  if (status == 4) {
                    this.sendRequest('POST', this.tasksList[ind], '/set_operation', {}, true, true)
                      .then(response => {

                        this.$set(this.tasksList[ind], 'status', status)
                        this.$set(this.tasksList[ind], 'date', this.$moment().format())

                      })
                      .catch(err => {
                        this.$store.commit('setError', err)
                      })
                  }
                })
                .catch(err => {
                  this.$store.commit('setError', err)
                })
            })
            // const url = this.tasksList[ind].label;
            // const windowName = 'myWindow';
            // const windowFeatures = 'width=600,height=400,toolbar=no,location=no';

            // window.open(url, windowName, windowFeatures);
          }
        })
      } else {
        this.sendRequest('PUT', { id, status }, '/change_task', {}, true, true)
          .then(response => {
            this.$set(this.tasksList[ind], 'status', status)
            this.$set(this.tasksList[ind], 'date', this.$moment().format())
            if (status == 4) {
              this.sendRequest('POST', this.tasksList[ind], '/set_operation', {}, true, true)
                .then(response => {

                  this.$set(this.tasksList[ind], 'status', status)
                  this.$set(this.tasksList[ind], 'date', this.$moment().format())

                })
                .catch(err => {
                  this.$store.commit('setError', err)
                })
            }
          })
          .catch(err => {
            this.$store.commit('setError', err)
          })
      }
    },
    selectAll(check) {
      if (check == true) {
        this.$set(this, 'nomenclatureSelect', this.tasksList.reduce((result, item) => {
          if (this.showRow(item.status)) {
            return [...result, item['_id']]
          } else {
            return result
          }
        }, []))
      } else {
        this.$set(this, 'nomenclatureSelect', [])
      }
    },
    generateFile() {
      if (this.nomenclatureSelect.length == 0) {
        this.$store.commit('setError', 'Выбирите хотя бы одну задачу')
      } else {
        this.sendRequest('POST', { ids: this.nomenclatureSelect }, '/file_generate', {}, true, true)
          .then(response => {
            this.$set(this, 'hasLoad', true)
            this.$cookies.set("hasLoad", 1, {
              path: "/",
              maxAge: this.$moment().unix() + 5,
            });
            location.href = "/public/upload/file/" + response.file;
          })
          .catch(err => {
            this.$store.commit('setError', err)
          })
      }
    }
  }
}
</script>
<style lang="scss">
#list-page {
  .top-line {
    padding: 10px 20px;
    border-bottom: 1px solid #E0E0E0;

    .watch {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: #1A1A1A;
    }
  }

  .table-scroll {
    tr {
      @media screen and (max-width: 560px) {
        display: grid;
        gap: 3px;
        grid-template-columns: repeat(5, calc(20% - 3px));
      }

      &.role-3 {
        @media screen and (max-width: 560px) {
          display: grid;
          gap: 3px;
          grid-template-columns: 20px repeat(5, calc(20% - 7px));
        }

        td {
          &:nth-child(3) {
            @media screen and (max-width: 560px) {
              grid-row: 1;
              grid-column: 3;
            }
          }

          &:nth-child(4) {
            @media screen and (max-width: 560px) {
              grid-row: 2;
              grid-column: 3/7;
            }
          }


          &:nth-child(5) {
            @media screen and (max-width: 560px) {
              grid-row: 2;
              grid-column: 1 / 3;
            }
          }

          &:nth-child(6) {
            @media screen and (max-width: 560px) {
              grid-row: 3;
              grid-column: 1 / 6;
            }
          }

          &:nth-child(7) {
            @media screen and (max-width: 560px) {
              grid-row: 1;
              grid-column: 4;
            }
          }

          &:nth-child(8) {
            @media screen and (max-width: 560px) {
              grid-row: 1;
              grid-column: 5;
            }
          }

          &:nth-child(9) {
            @media screen and (max-width: 560px) {
              grid-row: 1;
              grid-column: 6;
            }
          }

          &:nth-child(11) {
            @media screen and (max-width: 560px) {
              // grid-row: 1;
              grid-column: 1/7;

              div {
                height: 100%;
              }
            }
          }

          &:nth-child(12) {
            @media screen and (max-width: 560px) {
              grid-column: 1/7;
              text-align: right;
            }
          }
        }
      }

      td {
        &:nth-child(3) {
          @media screen and (max-width: 560px) {
            grid-row: 2;
            grid-column: 2 / 6;
          }
        }

        &:nth-child(4) {
          @media screen and (max-width: 560px) {
            grid-row: 2;
            grid-column: 1;
          }
        }

        &:nth-child(5) {
          @media screen and (max-width: 560px) {
            grid-row: 3;
            grid-column: 1 / 5;
          }
        }

        &:nth-child(6) {
          @media screen and (max-width: 560px) {
            grid-row: 1;
            grid-column: 4;
          }
        }

        &:nth-child(8) {
          @media screen and (max-width: 560px) {
            grid-row: 1;
            grid-column: 5;
          }
        }

        &:nth-child(10) {
          @media screen and (max-width: 560px) {
            // grid-row: 1;
            grid-column: 1/6;

            div {
              height: 100%;
            }
          }
        }

        &:nth-child(11) {
          @media screen and (max-width: 560px) {
            grid-column: 1/6;
            text-align: right;
          }
        }
      }

      .ipt {
        display: flex;
        align-items: center;

        .v-input {
          min-width: 65px;
          margin-right: 5px;
        }
      }
    }

    .tg-action-btn {
      button {
        &.process {
          background: #FFB800 !important;
          color: #1A1A1A !important;
        }

        &.outbtn {
          background: #A13453 !important;
          color: #fff !important;
        }

        &.success {
          background: #629F33 !important;
          color: #fff !important;
        }
      }
    }

    .bold {
      font-weight: bold;
    }

    .img {
      max-width: 200px;

      img {
        max-width: 100%;
      }
    }

    .status {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 12px;
      padding: 7px 20px;
      white-space: nowrap;
      border: 1px solid;
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 16px;

      &.wait {
        background: #fff;
        border-color: #E0E0E0;
        color: #1A1A1A;
      }

      &.process {
        background: #FFB800;
        border-color: #FFB800;
        color: #1A1A1A;
      }

      &.out-status {
        background: #A13453;
        border-color: #A13453;
        color: #fff;
      }

      &.success {
        background: #629F33;
        border-color: #629F33;
        color: #fff;
      }
    }
  }

  .data-controll {
    margin-bottom: 15px;

    .row {
      padding: 0 18px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media screen and (max-width: 560px) {
        flex-direction: column;
      }
    }

    .btn-div {
      display: flex;
      align-items: center;

      button {
        background: #629F33;
        border-color: #629F33;
        color: #fff;
      }

      svg {
        margin-left: 10px;
        min-width: 24px;
        cursor: pointer;
      }
    }
  }

  .notStock {
    td {
      background: red;
    }
  }

  .fbo {
    td {
      background: #beffc8 !important;
    }
  }
}
</style>

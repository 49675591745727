<template>
    <div id="monitor-page">
        <Grid :column="1" :gap="10" :style="{ gridTemplateRow: 'auto  1fr' }">
            <div class="d-flex align-items-center justify-content-space-between top-line">
                <div class="d-flex align-items-center">
                    <v-btn to="/login" class="button mt-6" variant="tonal" color="primary" v-if="!user">
                        Войти
                    </v-btn>
                    <v-btn to="/main" class="button mt-6" variant="tonal" color="primary" v-else>
                        Перейти в ЛК
                    </v-btn>
                    <span>ВСЕГО АРТИКУЛОВ: {{ totalCountBarcodes }}; | ВСЕГО ИЗДЕЛИЙ: {{ totalCountGoods }}шт.| ВСЕГО
                        ЗАРАБОТОК: {{ totalAmount }}₽</span>
                </div>
                <div class="watch">{{ time }}</div>
            </div>
            <TableBlock>
                <template v-slot:body>
                    <tr v-for="g of tasksList" :key="'g-' + g.id" :class="{ notStock: g?.stock <= 5, fbo: g?.fbo }">
                        <td class="bold">{{ $moment(g.date).format('DD.MM.YYYY HH:mm') }}</td>
                        <td>{{ g.goods_id }}</td>
                        <td>{{ g.name }}</td>
                        <td class="img">
                            <template v-if="g.photo">
                                <img :src="`/public/upload/img/${g.photo}`">
                            </template>
                        </td>
                        <td>{{ g.description }}</td>
                        <td>
                            <template v-if="g.mp">
                                <b>МП:</b>{{ g.mp }}
                            </template>
                            <template v-else>
                                остаток: {{ g.stock || '—' }}
                            </template>
                        </td>
                        <td>{{ g.count }} шт.</td>
                        <td>{{ g.price }}₽/шт.</td>
                        <td>{{ g.total }}₽</td>
                        <td>{{ g.managerName }}</td>
                        <td>
                            <div class="status"
                                :class="{ wait: g.status == 1, process: g.status == 2, 'out-status': g.status == 3, success: g.status == 4 }">
                                <span>
                                    <template v-if="g.status == 1">
                                        В ожидании
                                    </template>
                                    <template v-else-if="g.status == 2">
                                        Взято в работу
                                    </template>
                                    <template v-else-if="g.status == 3">
                                        Переданно на склад
                                    </template>
                                    <template v-else-if="g.status == 4">
                                        Готово
                                    </template>
                                </span>
                            </div>
                        </td>
                    </tr>
                </template>
            </TableBlock>
        </Grid>

    </div>
</template>
<script>
import { mapState } from 'vuex'
import { globalMethods } from '@/mixins/globalMethods'
import moment from 'moment'
export default {
    name: 'MonitorPage',
    mixins: [globalMethods],
    data() {
        return {
            time: null,
            intervalListener: null,
            intervalLoadTasks: null,
            tasksList: []
        }
    },
    mounted() {
        const self = this
        this.loadTask()
        this.$set(this, 'time', this.$moment().format('HH:mm'))
        this.$set(this, 'intervalLoadTasks', setInterval(() => {
            new Promise((resolve, reject) => {
                resolve(self.loadTask())
            })
        }, 30000))
    },

    destroyed() {
        window.clearInterval(this.intervalListener);
        window.clearInterval(this.intervalLoadTasks);
    },
    computed: {
        ...mapState({
            WSMessage: state => state.WSMessage,
        }),
        totalCountBarcodes() {
            let list = this.tasksList.reduce((result, i) => {
                return {
                    ...result,
                    [i.goods_id]: 1
                }
            }, {})
            return Object.keys(list).length
        },
        totalCountGoods() {
            let count = this.tasksList.reduce((result, i) => {
                return result += parseInt(i.count)
            }, 0)
            return count
        },
        totalAmount() {
            let amount = this.tasksList.reduce((result, i) => {
                return result += parseFloat(i.total)
            }, 0)
            return amount
        },
        user() {
            if (this.$cookies.get('token')) {
                return true
            } else {
                return false
            }
        },
        watch() {
            return this.$moment().format('HH:mm')
        }
    },
    watch: {
        time: function () {
            const self = this
            this.intervalListener = setInterval(() => {
                self.$set(self, 'time', self.$moment().format('HH:mm'))
            }, 1000)
        },
        WSMessage: function (val) {
            if (typeof val === "object" && val !== null) {
                if (val?.action == 'changeTask') {
                    let findIndex = this.tasksList.findIndex(t => t['_id'] == val?.data?.id)
                    if (findIndex > -1) {
                        this.$set(this.tasksList[findIndex], 'status', val?.data?.status)
                        this.$set(this.tasksList[findIndex], 'date', this.$moment().format())
                        this.$set(this.tasksList[findIndex], 'managerName', val?.data?.manager)
                    }
                }
            }
        }
    },
    methods: {
        loadTask() {
            this.sendRequest('GET', { not_status: [3, 4, 5] }, '/get_tasks', {}, true, true)
                .then(response => {
                    let tasks = response.reverse(),
                        fbo = tasks.filter(t => t.fbo),
                        notFbo = tasks.filter(t => !t.fbo).sort((a, b) => a.stock - b.stock);

                    this.$set(this, 'tasksList', [...fbo, ...notFbo])
                })
                .catch(err => {
                    this.$store.commit('setError', err)
                })
        }
    }
}
</script>
<style lang="scss">
#monitor-page {
    .top-line {
        padding: 10px 20px;
        border-bottom: 1px solid #E0E0E0;

        .watch {
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            color: #1A1A1A;
        }
    }

    .table-scroll {
        padding: 0 20px;

        tr {
            @media screen and (max-width: 560px) {
                display: grid;
                gap: 3px;
                grid-template-columns: repeat(5, calc(20% - 3px));
            }

            td {
                &:nth-child(3) {
                    @media screen and (max-width: 560px) {
                        grid-row: 2;
                        grid-column: 2 / 6;
                    }
                }

                &:nth-child(4) {
                    @media screen and (max-width: 560px) {
                        grid-row: 2;
                        grid-column: 1;
                    }
                }

                &:nth-child(5) {
                    @media screen and (max-width: 560px) {
                        grid-row: 3;
                        grid-column: 1 / 5;
                    }
                }

                &:nth-child(8) {
                    @media screen and (max-width: 560px) {
                        grid-row: 1;
                        grid-column: 5;
                    }
                }

                &:nth-child(10) {
                    @media screen and (max-width: 560px) {
                        // grid-row: 1;
                        grid-column: 1/5;

                        div {
                            height: 100%;
                        }
                    }
                }

                &:nth-child(11) {
                    @media screen and (max-width: 560px) {
                        text-align: right;
                    }
                }
            }
        }

        .bold {
            font-weight: bold;
        }

        .img {
            max-width: 200px;

            img {
                max-width: 100%;
            }
        }

        .status {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 12px;
            padding: 7px 20px;
            white-space: nowrap;
            border: 1px solid;
            font-style: normal;
            font-weight: 700;
            font-size: 13px;
            line-height: 16px;

            &.wait {
                background: #fff;
                border-color: #E0E0E0;
                color: #1A1A1A;
            }

            &.process {
                background: #FFB800;
                border-color: #FFB800;
                color: #1A1A1A;
            }

            &.out-status {
                background: #A13453 !important;
                border-color: #A13453;
                color: #fff !important;
            }

            &.success {
                background: #629F33;
                border-color: #629F33;
                color: #fff;
            }
        }
    }

    .notStock {
        td {
            background: red;
        }
    }

    .fbo {
        td {
            background: #beffc8 !important;
        }
    }
}
</style>
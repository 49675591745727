<template>
    <div class="monitor-page">
        <slot />
        <Error />
    </div>
</template>
<script>
import Error from "../components/Errors";
export default {
    name: "default",
    components: { Error },
}
</script>
<style lang="scss">
.login-page {
    height: 100vh;
    width: 100vw;
    background-color: #fff;
    font-family: 'Circe';
    font-style: normal;
}
</style>
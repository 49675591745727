<template>
    <div id="drop-orders-page">
        <Block padding="5">
            <div class="grid-row">
                <form @submit.prevent="find()">
                    <div>
                        <span>ID заказа:</span>
                        <v-text-field v-model.number="filter.id" autocomplete="off" outlined
                            class="input"></v-text-field>
                    </div>
                    <v-btn type="submit" class="button mt-6" variant="tonal" color="primary">
                        Найти
                    </v-btn>
                </form>
                <v-pagination :total-visible="5" v-model="currentPage" :length="totalPages"></v-pagination>
            </div>
        </Block>
        <Block :style="{ overflow: 'hidden' }">
            <TableBlock>
                <template v-slot:head>
                    <tr v-if="!isMobyle">
                        <th>ID</th>
                        <th>Merlion ID</th>
                        <th>Дата</th>
                        <th>
                            <div class="tb-grid">
                                <div>Товары</div>
                                <div>Название</div>
                                <div>Количество</div>
                                <div>Закупка</div>
                                <div>Стоимость</div>
                            </div>
                        </th>
                    </tr>
                </template>
                <template v-slot:body>
                    <tr v-for="g of goods" :key="'g-' + g.No">
                        <td>
                            {{ g.id }}
                        </td>
                        <td>
                            {{ g.merlion_id || '' }}
                        </td>
                        <td>
                            {{ g.date }}
                        </td>
                        <td>
                            <div class="tb-grid" v-for="gg in g.goods" :key="g.id + '|' + gg.goods_id">
                                <div>{{ gg.goods_id }}</div>
                                <div>{{ gg.name || '—' }}</div>
                                <div>{{ gg.count }}</div>
                                <div>—</div>
                                <div>{{ gg.price }}</div>
                            </div>
                        </td>
                    </tr>
                </template>
            </TableBlock>
        </Block>
    </div>
</template>
<script>
import { mapState } from 'vuex'
// import { actionTypes } from '@/store/modules/auth'
import { globalMethods } from '@/mixins/globalMethods'
import { resolve } from 'path'
import Block from '@/components/Block.vue'

export default {
    components: { Block },
    name: 'dropOrdersPage',
    // components: {
    // },
    mixins: [globalMethods],
    data() {
        return {
            arrGoods: [],
            page: 0,
            filter: {},
            goods: [],
            totalPages: 0,
            currentPage: 1
        }
    },
    created() {
        if (!this.checkAccess('drop')) {
            this.$router.push({ name: 'Main' });
        }
    },
    mounted() {
        this.find()
    },

    destroyed() {

    },
    computed: {
        ...mapState({
            user: state => state.user,
        }),
        isMobyle() {
            return this.windowWidth <= 560
        },
        gridLine() {
            return { gridTemplateColumns: '100px 1fr 150px', alignItems: 'center' }
        }
    },
    watch: {
        'filter.id': function (val) {
            this.page = 0;
            this.currentPage = 1;
        },
        currentPage: function () {
            this.find()
        }
    },
    methods: {
        selectAll(check) {
            if (check == true) {
                this.$set(this, 'arrGoods', this.goods.reduce((result, item) => {
                    return [...result, item['No']]
                }, []))
            } else {
                this.$set(this, 'arrGoods', [])
            }
        },
        changeGoods(value, id, items) {
            this.sendRequest('PUT', { value, id, items }, '/change_merlion_goods', {}, true, true)
                .then(response => {
                    // this.$set(this, 'goodsList', response)
                })
                .catch(err => {
                    this.$store.commit('setError', err)
                })
        },
        find() {
            let query = {
                filter: this.filter,
                page: this.currentPage - 1,
            }
            this.sendRequest('POST', query, '/get_merlion_orders', {}, true, true)
                .then(response => {
                    this.goods = response.orders
                    this.totalPages = Math.ceil(response.count / 100)
                })
                .catch(err => {
                    this.$store.commit('setError', err)
                })
        },
        save(mp) {
            if (!this.form[mp]) {
                this.$store.commit('setError', 'Укажите формулу')
            } else {
                this.sendRequest('POST', { mp, formula: this.form[mp] }, '/save_arithmetic', {}, true, true)
                    .then(response => { })
                    .catch(err => {
                        this.$store.commit('setError', err)
                    })
            }
        },
        reloadData(id) {
            this.sendRequest('PUT', { id }, '/reload_merlion', {}, true, true)
                .then(response => {
                    this.find()
                })
                .catch(err => {
                    this.$store.commit('setError', err)
                })
        }
    }
}
</script>
<style lang="scss">
#drop-page {
    .info {
        padding-left: 20px;
    }

    .grid-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;

        >div {
            // flex: 1;
        }

        form {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 5px;
            align-items: flex-end;

            @media screen and (max-width: 576px) {
                grid-template-columns: 100%;
            }
        }
    }

    .button.delete {
        background: #a22020;
        color: #fff;
    }

    .tb-grid {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 5px;
    }
}
</style>
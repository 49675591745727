<template>
    <div id="drop-goods-page">
        <Block padding="5">
            <div class="grid-row">
                <form @submit.prevent="find()">
                    <div>
                        <span>ID товара:</span>
                        <v-text-field v-model.number="filter.id" autocomplete="off" outlined class="input"></v-text-field>
                    </div>
                    <v-btn type="submit" class="button mt-6" variant="tonal" color="primary">
                        Найти
                    </v-btn>
                </form>
                <v-btn type="submit" class="button mt-6" variant="tonal" color="delete" @click="deleteGoods()">
                    <Icon iconName="trash" width="20" height="20" viewBox="0 0 16 16" strockeWidth="2" stroke="#fff" />
                    <!-- #a22020 -->
                </v-btn>
                <v-pagination :total-visible="5" v-model="currentPage" :length="totalPages"></v-pagination>
            </div>
        </Block>
        <Block :style="{ overflow: 'hidden' }">
            <TableBlock>
                <template v-slot:head>
                    <tr v-if="!isMobyle">
                        <th><v-checkbox color="#629F33" @change="selectAll"></v-checkbox></th>
                        <th></th>
                        <th>ID merlion</th>
                        <th>ID mphone</th>
                        <th>Название</th>
                        <th>Остаток</th>
                        <th>Закупочная цена</th>
                        <th>Цены</th>
                        <th>Спец. цена</th>
                    </tr>
                </template>
                <template v-slot:body>
                    <tr v-for="g of goods" :key="'g-' + g.No">
                        <td><v-checkbox color="#629F33" :id="`checkbox-${g['No']}`" :ref="`checkbox-${g['No']}`"
                                v-model="arrGoods" :value="g['No']"></v-checkbox></td>
                        <td>
                            <button @click="reloadData(g.No)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-refresh-ccw">
                                    <polyline points="1 4 1 10 7 10"></polyline>
                                    <polyline points="23 20 23 14 17 14"></polyline>
                                    <path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path>
                                </svg>
                            </button>
                        </td>
                        <td>
                            {{ g.No }}
                        </td>
                        <td>
                            {{ g.mphoneID || '—' }}
                        </td>
                        <td>
                            {{ g.Name || '—' }}
                        </td>
                        <td>
                            {{ g.AvailableClient_MSK || 0 }}
                        </td>
                        <td>
                            {{ g.PriceClientRUB_MSK || '—' }}
                        </td>
                        <td>
                            OZON: <b>{{ g.price_ozon }}</b><br>
                            <!-- формула OZON: <b>{{ g.formula_ozon }}</b><br> -->
                            SBER: <b>{{ g.price_sber }}</b><br>
                            <!-- формула SBER: <b>{{ g.formula_sber }}</b><br> -->
                            WB: <b>{{ g.price_wb }}</b><br>
                            <!-- формула WB: <b>{{ g.formula_wb }}</b><br> -->
                            Yandex: <b>{{ g.price_yandex }}</b><br>
                            <!-- формула Yandex: <b>{{ g.formula_yandex }}</b><br> -->
                        </td>
                        <td>
                            <v-text-field v-model.number="g.specPrice" autocomplete="off" outlined class="input"
                                @change="changeGoods($event, g.No, 'specPrice')"></v-text-field>
                        </td>
                    </tr>
                </template>
            </TableBlock>
        </Block>
    </div>
</template>
<script>
import { mapState } from 'vuex'
// import { actionTypes } from '@/store/modules/auth'
import { globalMethods } from '@/mixins/globalMethods'
import { resolve } from 'path'
import Block from '@/components/Block.vue'

export default {
    components: { Block },
    name: 'dropGoodsPage',
    // components: {
    // },
    mixins: [globalMethods],
    data() {
        return {
            arrGoods: [],
            page: 0,
            filter: {},
            goods: [],
            totalPages: 0,
            currentPage: 1
        }
    },
    created() {
        if (!this.checkAccess('drop')) {
            this.$router.push({ name: 'Main' });
        }
    },
    mounted() {
        this.find()
    },

    destroyed() {

    },
    computed: {
        ...mapState({
            user: state => state.user,
        }),
        isMobyle() {
            return this.windowWidth <= 560
        },
        gridLine() {
            return { gridTemplateColumns: '100px 1fr 150px', alignItems: 'center' }
        }
    },
    watch: {
        'filter.id': function (val) {
            this.page = 0;
            this.currentPage = 1;
        },
        currentPage: function () {
            this.find()
        }
    },
    methods: {
        deleteGoods() {
            let mphones = this.arrGoods.map(el => {
                let find = this.goods.find(g => g.No === el)
                return find.mphoneID
            }).filter(el => !!el)
            this.sendRequest('DELETE', { items: this.arrGoods, mphones: mphones || [] }, '/delete_merlion_goods', {}, true, true)
                .then(response => {
                    this.$set(this, 'arrGoods', [])
                    this.find()
                })
                .catch(err => {
                    this.$store.commit('setError', err)
                })
        },
        selectAll(check) {
            if (check == true) {
                this.$set(this, 'arrGoods', this.goods.reduce((result, item) => {
                    return [...result, item['No']]
                }, []))
            } else {
                this.$set(this, 'arrGoods', [])
            }
        },
        changeGoods(value, id, items) {
            this.sendRequest('PUT', { value, id, items }, '/change_merlion_goods', {}, true, true)
                .then(response => {
                    // this.$set(this, 'goodsList', response)
                })
                .catch(err => {
                    this.$store.commit('setError', err)
                })
        },
        find() {
            let query = {
                filter: this.filter,
                page: this.currentPage - 1,
            }
            this.sendRequest('POST', query, '/get_drop_goods', {}, true, true)
                .then(response => {
                    this.goods = response.goods
                    this.totalPages = Math.ceil(response.count / 100)
                })
                .catch(err => {
                    this.$store.commit('setError', err)
                })
        },
        save(mp) {
            if (!this.form[mp]) {
                this.$store.commit('setError', 'Укажите формулу')
            } else {
                this.sendRequest('POST', { mp, formula: this.form[mp] }, '/save_arithmetic', {}, true, true)
                    .then(response => { })
                    .catch(err => {
                        this.$store.commit('setError', err)
                    })
            }
        },
        reloadData(id) {
            this.sendRequest('PUT', { id }, '/reload_merlion', {}, true, true)
                .then(response => {
                    this.find()
                })
                .catch(err => {
                    this.$store.commit('setError', err)
                })
        }
    }
}
</script>
<style lang="scss">
#drop-page {
    .info {
        padding-left: 20px;
    }

    .grid-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;

        >div {
            // flex: 1;
        }

        form {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 5px;
            align-items: flex-end;

            @media screen and (max-width: 576px) {
                grid-template-columns: 100%;
            }
        }
    }

    .button.delete {
        background: #a22020;
        color: #fff;
    }
}
</style>
<template>
    <div id="settings-page">
        <Grid :column="1" :gap="20">
            <Block :style="{ overflow: 'hidden' }">
                <TableBlock>
                    <template v-slot:head>
                        <tr>
                            <th>Имя</th>
                            <th>Email</th>
                            <th v-for="(name, slug) of pages" :key="'page-' + slug">{{ name }}</th>
                        </tr>
                    </template>
                    <template v-slot:body>
                        <tr v-for="(staff, ind) of staffs" :key="'staff-' + staff.id">
                            <td class="nowrap">{{ staff.name }}</td>
                            <td class="nowrap">{{ staff.email }}</td>
                            <td v-for="(name, slug) of pages" :key="staff.id + '-page-' + slug">
                                <v-checkbox color="#629F33" v-model="staff.settings" :value="slug"
                                    @change="setSettionOption($event, slug, staff.id, ind)"></v-checkbox>
                            </td>
                        </tr>
                    </template>
                </TableBlock>
                <template v-if="staffs.length == 0">
                    <div class="no-data">Сотрудников еще нет</div>
                </template>
            </Block>
        </Grid>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import { globalMethods } from '@/mixins/globalMethods'
export default {
    name: 'SettingsPage',
    mixins: [globalMethods],
    created() {
        if (this.$store.state.user?.owner !== 1) {
            if (!this.checkAccess(this.$route.name)) {
                this.$router.push({ name: 'Main' });
            }
        }
    },
    computed: {
        ...mapState({
            language: state => state.language,
            user: state => state.user,
        }),
        pages: function () {
            return this.menu.reduce((result, item) => {
                let slug = item.url.substring(1);
                return {
                    ...result,
                    [slug]: item.name
                }
            }, {})
        },
    },
    data() {
        return {
            reportFile: null,
            staffs: [],
        }
    },
    mounted() {
        this.sendRequest('GET', {}, '/get_manegers', {}, true, true)
            .then(response => {
                let me = {
                    id: this.user['_id'],
                    name: this.user.name,
                    email: this.user.email,
                    settings: this.user.settings || [],
                }
                this.$set(this, 'staffs', [me, ...response])
            })
            .catch(err => {
                this.$store.commit('setError', err)
            })
    },
    watch: {
        language: function (val) {
            this.$forceUpdate()
        }
    },
    methods: {
        setSettionOption(settingsList, option, user, userInd) {
            const state = settingsList.includes(option)
            let obj = {
                id: user,
                option,
                state
            }

            this.sendRequest('PUT', obj, '/change_settings', {}, true, true)
                .then(response => {
                    if (state == false) {
                        this.$delete(this.staffs[userInd].settings, settingsList.indexOf(option))
                    }
                })
                .catch(err => {
                    this.$store.commit('setError', err)
                })
        }
    }
}
</script>
<style lang="scss">
#settings-page {
    .nowrap {
        white-space: nowrap;
    }

    table {

        th,
        td {
            &:first-child {
                position: sticky;
                left: 0;
                z-index: 1;
            }
        }
    }
}
</style>
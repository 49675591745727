<template>
    <div id="drop-page">
        <Grid :column="1" :gap="20">
            <div class="submenu">
                <router-link :to="{ path: '/drop/marvel/arithmetic' }" exact>Арифметика</router-link>
                <router-link :to="{ path: '/drop/marvel/goods' }" exact>Товары</router-link>
                <router-link :to="{ path: '/drop/marvel/marvel' }" exact>Marvel</router-link>
                <router-link :to="{ path: '/drop/marvel/new' }" exact>New</router-link>
                <router-link :to="{ path: '/drop/marvel/marvel_orders' }" exact>Заказы</router-link>
            </div>            
            <router-view></router-view>
        </Grid>
    </div>
</template>
<script>
import { mapState } from 'vuex'
// import { actionTypes } from '@/store/modules/auth'
import { globalMethods } from '@/mixins/globalMethods'

export default {
    name: 'dropPage',
    // components: {
    // },
    mixins: [globalMethods],
    data() {
        return {
            mp: [
                'ozon',
                'yandex',
                'wb',
                'sber',
            ],
            form: {
                'ozon': '',
                'yandex': '',
                'wb': '',
                'sber': '',
            }


        }
    },
    created() {
        // if (!this.checkAccess(this.$route.name)) {
        //     this.$router.push({ name: 'Main' });
        // }
    },
    mounted() {
        // console.log(11111111)
        // console.log(11111111)
        // console.log(11111111)
        // console.log(this.$route)

        // const profitList = {
        //     "1": 100,
        //     "101": 200,
        //     "201": 400,
        //     "401": 600,
        //     "601": 900,
        //     "901": 1300,
        //     "1301": 1700,
        //     "1701": 2500,
        //     "2501": 3500,
        //     "3501": 5000,
        //     "5001": 7500,
        //     "7501": 10000,
        //     "10001": 15000,
        //     "15001": 20000,
        //     "20001": 25000,
        //     "25001": 30000,
        //     "30001": 35000,
        //     "35001": 40000,
        //     "40001": 45000,
        //     "45001": 50000,
        //     "50001": 70000,
        //     "70001": 90000,
        //     "90001": 110000,
        //     "110001": 150000
        // };

        // const numberToFind = 8456;

        // let foundRange = null;
        // let foundValue = null;

        // for (const start in profitList) {
        //     if (numberToFind >= Number(start) && numberToFind <= profitList[start]) {
        //         foundRange = `${start} - ${profitList[start]}`;
        //         foundValue = profitList[start]
        //         break;
        //     }
        // }

        // if (foundRange) {
        //     console.log(`Число ${numberToFind} находится в диапазоне ${foundRange} значени ${foundValue}`);
        // } else {
        //     console.log(`Число ${numberToFind} не входит в ни один из диапазонов`);
        // }
    },

    destroyed() {

    },
    computed: {
        ...mapState({
            user: state => state.user,
            pageName: state => state.pageName,
        }),
        isMobyle() {
            return this.windowWidth <= 560
        },
        gridLine() {
            return { gridTemplateColumns: '100px 1fr 150px', alignItems: 'center' }
        }
    },
    methods: {
        save(mp) {
            if (!this.form[mp]) {
                this.$store.commit('setError', 'Укажите формулу')
            } else {
                this.sendRequest('POST', { mp, formula: this.form[mp] }, '/save_arithmetic', {}, true, true)
                    .then(response => { })
                    .catch(err => {
                        this.$store.commit('setError', err)
                    })
            }
        }
    }
}
</script>
<style lang="scss">
#drop-page {
    nav {
        display: flex;
        align-items: center;
        gap: 20px;

        a {
            color: #000 !important;
            text-decoration: none;
            border-bottom: 1px solid #000;

            &.router-link-exact-active {
                color: #629f33 !important;
                border-bottom: 1px solid rgba(0, 0, 0, 0);
            }
        }
    }

    .info {
        padding-left: 20px;
    }
}
</style>
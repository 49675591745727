import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "../views/Login"
import Main from "../views/Main"
import Manegers from "../views/Manegers"
import Monitor from "../views/Monitor"
import Goods from "../views/Goods"
import List from "../views/List"
import Сalculation from "../views/Сalculation"
import Stocks from "../views/Stocks"
import StockArithmetic from "../views/Stocks/arithmetic"
import Logistics from "../views/Logistics"
import Commissions from "../views/Commissions"
import Fbo from "../views/Goods/Fbo"
import Drop from "../views/Drop"
import DropMerlion from "../views/Drop/Merlion"
import DropMerlionArithmetic from "../views/Drop/Merlion/arithmetic"
import DropMerlionGoods from "../views/Drop/Merlion/goods"
import DropMerlionNew from "../views/Drop/Merlion/New"
import DropMerlionOrders from "../views/Drop/Merlion/Orders"
import DropMerlionMerlion from "../views/Drop/Merlion/Merlion"

import DropMarvel from "../views/Drop/Marvel"
import DropMarvelArithmetic from "../views/Drop/Marvel/arithmetic"
import DropMarvelGoods from "../views/Drop/Marvel/goods"
import DropMarvelNew from "../views/Drop/Marvel/New"
import DropMarvelOrders from "../views/Drop/Marvel/Orders"
import DropMarvelMarvel from "../views/Drop/Marvel/Marvel"

import JoinExcel from "../views/JoinExcel"
import Settings from "../views/Settings"
import Scanner from "../views/Scanner"
import Price from "../views/Price"
import DambulShop from "../views/DambulShop"
import DambulShopArithmetic from "../views/DambulShop/arithmetic"

import axios from "@/api/axios"
import store from "@/store"

Vue.use(VueRouter)
const routes = [
    {
        path: '/',
        name: 'Monitor',
        component: Monitor,
        meta: { layout: "monitor-layout" }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: { layout: "login-layout" }
    },
    {
        path: '/main',
        name: 'Main',
        component: Main,
        meta: { requiresAuth: true, layout: "default-layout" }
    },
    {
        path: '/manegers',
        name: 'Manegers',
        component: Manegers,
        meta: { requiresAuth: true, layout: "default-layout", role: 1 }
    },
    {
        path: '/goods',
        name: 'Goods',
        component: Goods,
        meta: { requiresAuth: true, layout: "default-layout", role: 1, name: 'Товары' },
        children: [
            {
                path: 'fbo',
                name: 'FBO',
                component: Fbo,
                meta: { requiresAuth: true, layout: "default-layout", name: 'FBO' },
            },
        ]
    },
    {
        path: '/stocks',
        name: 'Stocks',
        component: Stocks,
        meta: { requiresAuth: true, layout: "default-layout", role: 1 },
        children: [
            {
                path: '/stocks/arithmetic',
                name: 'StockArithmetic',
                component: StockArithmetic,
                meta: { requiresAuth: true, layout: "default-layout", name: 'FBO' },
            },
        ]

    },
    {
        path: '/logistics',
        name: 'Logistics',
        component: Logistics,
        meta: { requiresAuth: true, layout: "default-layout", role: 1 }
    },
    {
        path: '/commissions',
        name: 'Commissions',
        component: Commissions,
        meta: { requiresAuth: true, layout: "default-layout", role: 1 }
    },
    {
        path: '/list',
        name: 'List',
        component: List,
        meta: { requiresAuth: true, layout: "default-layout", role: 2 }
    },
    {
        path: '/calculation',
        name: 'Calculation',
        component: Сalculation,
        meta: { requiresAuth: true, layout: "default-layout", role: 2 }
    },
    {
        path: '/drop',
        name: 'Drop',
        component: Drop,
        meta: { requiresAuth: true, layout: "default-layout", role: 1, name: 'Drop' },
        children: [
            {
                path: 'merlion',
                name: 'Merlion',
                component: DropMerlion,
                meta: { requiresAuth: true, layout: "default-layout", role: 1, name: 'Merlion' },
                children: [
                    {
                        path: 'arithmetic',
                        name: 'Arithmetic',
                        component: DropMerlionArithmetic,
                        meta: { requiresAuth: true, layout: "default-layout", name: 'Арифметика' },
                    },
                    {
                        path: 'goods',
                        name: 'DropGoods',
                        component: DropMerlionGoods,
                        meta: { requiresAuth: true, layout: "default-layout", name: 'Товары' },
                    },
                    {
                        path: 'new',
                        name: 'DropNew',
                        component: DropMerlionNew,
                        meta: { requiresAuth: true, layout: "default-layout", name: 'Новые товары' },
                    },
                    {
                        path: 'merlion',
                        name: 'Merlion',
                        component: DropMerlionMerlion,
                        meta: { requiresAuth: true, layout: "default-layout", role: 1 }
                    },
                    {
                        path: 'merlion_orders',
                        name: 'MerlionOrders',
                        component: DropMerlionOrders,
                        meta: { requiresAuth: true, layout: "default-layout", role: 1 }
                    },
                ]

            },            
            {
                path: 'marvel',
                name: 'Marvel',
                component: DropMarvel,
                meta: { requiresAuth: true, layout: "default-layout", role: 1, name: 'Marvel' },
                children: [
                    {
                        path: 'arithmetic',
                        name: 'Arithmetic',
                        component: DropMarvelArithmetic,
                        meta: { requiresAuth: true, layout: "default-layout", name: 'Арифметика' },
                    },
                    {
                        path: 'goods',
                        name: 'DropGoods',
                        component: DropMarvelGoods,
                        meta: { requiresAuth: true, layout: "default-layout", name: 'Товары' },
                    },
                    {
                        path: 'new',
                        name: 'DropNew',
                        component: DropMarvelNew,
                        meta: { requiresAuth: true, layout: "default-layout", name: 'Новые товары' },
                    },
                    {
                        path: 'marvel',
                        name: 'Marvel',
                        component: DropMarvelMarvel,
                        meta: { requiresAuth: true, layout: "default-layout", role: 1 }
                    },
                    {
                        path: 'marvel_orders',
                        name: 'MarvelOrders',
                        component: DropMarvelOrders,
                        meta: { requiresAuth: true, layout: "default-layout", role: 1 }
                    },
                ]

            },
        ]

    },
    {
        path: '/dambul-shop',
        name: 'Dambul-Shop',
        component: DambulShop,
        meta: { requiresAuth: true, layout: "default-layout", role: 1, name: 'Dambul-Shop' },
        children: [
            {
                path: 'arithmetic',
                name: 'Arithmetic',
                component: DambulShopArithmetic,
                meta: { requiresAuth: true, layout: "default-layout", name: 'Арифметика' },
            },
        ]

    },
    {
        path: '/scanner',
        name: 'Scanner',
        component: Scanner,
        meta: { layout: "login-layout", }
    },
    {
        path: '/join_excel',
        name: 'JoinExcel',
        component: JoinExcel,
        meta: { requiresAuth: true, layout: "default-layout", role: 2 }
    },
    {
        path: '/settings',
        name: 'Settings',
        component: Settings,
        meta: { requiresAuth: true, layout: "default-layout", role: 1 }
    },
    {
        path: '/price',
        name: 'Price',
        component: Price,
        meta: { requiresAuth: true, layout: "default-layout", role: 1 }
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(async (to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (Vue.$cookies.get('token')) {
            await new Promise((resolve, reject) => {
                axios.defaults.headers.common['Content-Type'] = 'application/json';

                let headers = {
                    withCredentials: true,
                }

                const token = Vue.$cookies.get('token')
                if (token) {
                    headers['x-access-token'] = token
                }

                axios.request({
                    url: '/verify_token',
                    method: 'GET',
                    headers: headers
                })
                    .then(async ({ data }) => {
                        if (data.auth == true) {
                            if (store.state.user === null) {
                                await axios.request({
                                    url: '/load_profile',
                                    method: 'GET',
                                    headers: headers
                                })
                                    .then(({ data }) => {
                                        store.commit('setUser', data)
                                        resolve(true)
                                    })
                                    .catch((err) => {
                                        reject(3)
                                    })
                            } else {
                                resolve(true)
                            }
                        } else {
                            reject(2)
                        }
                    })
                    .catch((err) => {
                        reject(err)
                    })
            }).then(r => {
                next()
            }).catch(err => {
                Vue.$cookies.remove("token");
                next({ path: '/login' })
            })
        } else {
            next({ path: '/login' })
        }
    } else {
        next()
    }

})

export default router
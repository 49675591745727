<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import Error from "@/components/Errors";
import { mapState } from 'vuex'
import { globalMethods } from '@/mixins/globalMethods'
import { debounce } from 'lodash'

export default {
  name: 'App',
  components: {
    Error,
  },
  mixins: [globalMethods],
  computed: {
    layout() {
      return this.$route.meta.layout || "default-layout"
    },
    ...mapState({
      socket: state => state.socket,
    })
  },
  mounted() {
    this.wsConnect()
    this.updateWindowWidth = debounce(this.updateWindowWidth, 200)
    window.addEventListener('resize', this.updateWindowWidth);
  },
  methods: {
    updateWindowWidth() {
      this.$store.commit('setWindowWidth', window.innerWidth)
    },
    wsConnect() {
      const self = this
      const wssUrl = process.env.NODE_ENV !== 'production' ? 'ws://monitor.dambul-shop.ru' : 'wss://monitor.dambul-shop.ru/ws'
      this.$store.commit(
        "setSocket",
        new WebSocket(wssUrl)
      );
      // this.socket.addEventListener("message", event => {
      //   const message = JSON.parse(event.data);
      // });
      this.socket.onmessage = function (message) {
        let messageData = self.wsParseAnswer(message.data);
        if (messageData) {
          self.$store.commit('setWSMessage', messageData)
        }
      }
      this.socket.onclose = function (event) {
        if (event.wasClean) {
          console.log(
            `[close] Соединение закрыто чисто, код=${event.code} причина=${event.reason}`
          );
        } else {
          setTimeout(self.wsConnect, 1000);
        }
      };
    },
  }
}
</script>

<style>
#app {}
</style>

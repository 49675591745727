<template>
    <div id="calculation-page">
        <Grid :column="isMobyle ? 1 : 3" :gap="10">
            <Block padding="20" v-for="(items, m) of operations.list" :key="m">
                <div class="caption">{{ m }}</div>
                <template v-for="(i, ind) in breakdown(items)">
                    <div class="month" :key="m+ind">
                        <div class="month__name">{{ ind == 0 ? 'до 15го включительно' : 'после 15го' }}</div>
                        <div class="month__items">
                            <div class="month__items__item" v-for="item of i.list" :key="m+ind+item.task_id">
                                <div class="date"><span>{{ $moment(item.date).format('DD') }}</span> | {{
                                    $moment(item.date).format('HH:mm')
                                }}
                                </div>
                                <div class="goods">#{{ item.goods_id }}</div>
                                <div class="count">{{ item.count }}({{ item.price }}₽)</div>
                                <div class="total">{{ item.total }}₽</div>
                            </div>
                        </div>
                        <div class="month__total">
                            <div class="date">Итого:</div>
                            <div class="total">{{ i.total }}₽</div>
                        </div>
                    </div>
                </template>
            </Block>
        </Grid>
    </div>
</template>
<script>
import { mapState } from 'vuex'
// import { actionTypes } from '@/store/modules/auth'
import { globalMethods } from '@/mixins/globalMethods'
export default {
    name: 'CalculationPage',
    // components: {
    // },
    mixins: [globalMethods],
    data() {
        return {

        }
    },
    created() {
        if (!this.checkAccess(this.$route.name)) {
            this.$router.push({ name: 'Main' });
        }
    },
    computed: {
        ...mapState({
            user: state => state.user,
            windowWidth: state => state.windowWidth
        }),
        isMobyle() {
            return this.windowWidth <= 560
        },
        operations() {
            let total = {}
            let list = {}
            let a = [...this.user.operations].reverse().map((item) => {
                let dt = this.$moment(item.date).format('MM.YYYY')
                if (!total[dt]) {
                    total[dt] = 0
                }
                if (!list[dt]) {
                    list[dt] = []
                }
                total[dt] += parseFloat(item.total)
                list[dt].push(item)
            })

            return { list, total }
        }
    },
    watch: {

    },
    methods: {
        breakdown(items) {
            let start = { list: [], total: 0 },
                end = { list: [], total: 0 }
            items.reverse().forEach(el => {
                if (this.$moment(el.date).format('DD') <= 15) {
                    start.list.push(el)
                    start.total += el.total
                } else {
                    end.list.push(el)
                    end.total += el.total
                }

            });
            return [start, end]
        }
    }
}
</script>
<style lang="scss">
@import "@/assets/scss/global-styles.scss";

#calculation-page {
    .caption {
        padding-bottom: 10px;
        font-weight: bold;
        border-bottom: 1px solid $color-gray;
        margin-bottom: 5px;
        font-size: 14pt;
    }

    .month {
        margin-bottom: 20px;
        &__name {
            padding-bottom: 10px;
            font-weight: bold;
            border-bottom: 1px solid $color-gray;
            margin-bottom: 5px;
            font-size: 12pt;
        }

        &__items {
            max-height: 100px;
            overflow-x: auto;

            &__item {
                display: grid;
                grid-template-columns: 78px 2fr 78px 1fr;

                &:not(:last-child) {
                    margin-bottom: 5px;
                }

                .date {
                    span {
                        font-weight: bold;
                    }
                }

                .total {
                    font-weight: bold;
                    text-align: right;
                }

            }
        }

        &__total {
            display: grid;
            grid-template-columns: 78px 1fr 78px auto;
            border-top: 1px solid $color-gray;
            margin-top: 10px;
            padding-top: 5px;

            .date {
                grid-column: 1 / 4;
                font-weight: bold;
            }

            .total {
                font-weight: bold;
            }
        }
    }
}
</style>
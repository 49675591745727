<template>
    <div id="price-page">
        <Grid :column="1" :gap="20">
            <Block padding="5">
                <div class="pag">
                    <div>страница: {{ currentPage }} из {{ goodsTotalList.length }}</div>
                    <div>
                        <v-btn variant="outlined" class="btn-w-border" @click="changePage('prev')">
                            <Icon width="24" height="24" viewBox="0 0 24 24" strockeWidth="2.2"
                                :iconName="'chevron-left'" />
                        </v-btn>
                        <v-btn variant="outlined" class="btn-w-border" @click="changePage('next')">
                            <Icon width="24" height="24" viewBox="0 0 24 24" strockeWidth="2.2"
                                :iconName="'chevron-right'" />
                        </v-btn>
                    </div>
                </div>
            </Block>
            <Block padding="5">
                <div class="grid-row">
                    <form @submit.prevent="filter()">
                        <div>
                            <span>ID товара:</span>
                            <v-text-field v-model.number="goodsId" autocomplete="off" outlined class="input"></v-text-field>
                        </div>
                        <div>
                            <span>МП:</span>
                            <SelectBlock v-model="mp" :items="marketplaces" placeholder="Выберите">
                            </SelectBlock>
                        </div>
                        <div>
                            <span>Тип:</span>
                            <SelectBlock v-model="type" :items="findFields" placeholder="Искать по">
                            </SelectBlock>
                        </div>
                        <v-btn type="submit" class="button mt-6" variant="tonal" color="primary">
                            Найти
                        </v-btn>
                        <div v-html="countItemsHtml"></div>
                    </form>
                    <div>
                        <v-btn type="submit" class="button mt-6" variant="tonal" color="primary"
                            @click="showModal('addPricePromotion')">
                            <Icon width="30" height="30" viewBox="-1 -1 18 18" strockeWidth="2.2" :iconName="'plus'" />
                        </v-btn>
                        <v-btn type="submit" class="button mt-6" variant="tonal" color="delete"
                            @click="deletePricePromotion()">
                            <Icon iconName="trash" width="20" height="20" viewBox="0 0 16 16" strockeWidth="2"
                                stroke="#fff" />
                            <!-- #a22020 -->
                        </v-btn>
                    </div>
                </div>
            </Block>
            <Block>
                <TableBlock>
                    <template v-slot:head>
                        <tr>
                            <th><v-checkbox color="#629F33" @change="selectAll"></v-checkbox></th>
                            <th>#</th>
                            <th>МП</th>
                            <th>действует ДО</th>
                            <th>начинает действовать с</th>
                            <th>Цена</th>
                            <th>Тип</th>
                            <!-- <th>Название</th>
                            <th>WB</th>
                            <th>OZON</th>
                            <th>Yandex</th>
                            <th>Sber</th> -->
                            <!-- <th></th> -->
                            <!-- <th></th> -->
                        </tr>
                    </template>
                    <template v-slot:body>
                        <tr v-for="g of goodsList" :key="'g-' + g['_id']">
                            <td><v-checkbox color="#629F33" :id="`checkbox-${g['_id']}`" :ref="`checkbox-${g['_id']}`"
                                    v-model="arrPP" :value="g['_id']"></v-checkbox></td>
                            <td>{{ g.id }}</td>
                            <td>{{ g.mp }}</td>
                            <td>{{ $moment(g.date, 'YYYY-MM-DD_HH:mm').format('DD.MM.YYYY HH:mm') }}</td>
                            <td>{{ isActive(g.date_start) }}</td>
                            <td>
                                <v-text-field v-model.number="g.price" autocomplete="off" outlined class="input"
                                    @change="changeGoods($event, g.id, 'price')"></v-text-field>
                            </td>
                            <td>
                                {{ g?.extraCharge ? 'Наценка' : 'Акция' }}
                            </td>
                            <!-- <td>{{ g.name }}</td> -->
                            <!-- <td>
                                <v-text-field v-model.number="g.wb" autocomplete="off" outlined class="input"
                                    @change="changeGoods($event, g.id, 'wb')"></v-text-field>
                            </td>
                            <td>
                                <v-text-field v-model.number="g.ozon" autocomplete="off" outlined class="input"
                                    @change="changeGoods($event, g.id, 'ozon')"></v-text-field>
                            </td>
                            <td>
                                <v-text-field v-model.number="g.yandex" autocomplete="off" outlined class="input"
                                    @change="changeGoods($event, g.id, 'yandex')"></v-text-field>
                            </td>
                            <td>
                                <v-text-field v-model.number="g.sber" autocomplete="off" outlined class="input"
                                    @change="changeGoods($event, g.id, 'sber')"></v-text-field>
                            </td> -->
                            <!-- <td class="td-btn">
                                <Icon iconName="trash" width="12" height="13" viewBox="0 0 16 16" strockeWidth="1"
                                    stroke="#a22020" @click="deletePricePromotion(g['_id'], int)" />
                            </td> -->
                        </tr>
                    </template>
                </TableBlock>
            </Block>
        </Grid>
        <Modal ref="addPricePromotion" :notFooterBorder="true" padding="20" :hideHeader="true" size="medium">
            <template v-slot:body>
                <div class="import-dialog">
                    <Grid :column="2" :gap="20" :customStyle="{ alignItems: 'center' }">
                        <span>ID товара:</span>
                        <v-text-field v-model.number="newPP.id" autocomplete="off" outlined class="input"></v-text-field>
                        <span>Цена:</span>
                        <v-text-field v-model="newPP.price" autocomplete="off" outlined class="input"></v-text-field>
                        <span>МП:</span>
                        <SelectBlock v-model="newPP.mp" :items="marketplaces" placeholder="Выберите">
                        </SelectBlock>
                        <span>Действует ДО:</span>
                        <date-picker lang="ru" type="datetime" v-model="newPP.date" value-type="date"
                            :format="'DD.MM.YYYY HH:mm'" :default-value="new Date()" :show-second="false"
                            :show-minute="true" :minute-step="1" :disabled-date="disabledRange"
                            :disabled-time="disabledTimeRange"></date-picker>
                        <span>Действует С:</span>
                        <date-picker lang="ru" type="datetime" v-model="newPP.date_start" value-type="date"
                            :format="'DD.MM.YYYY HH:mm'" :default-value="new Date()" :show-second="false"
                            :show-minute="true" :minute-step="1" :disabled-date="disabledRange"
                            :disabled-time="disabledTimeRange"></date-picker>
                        <div>
                            <v-btn type="submit" class="button mt-6" variant="tonal" color="primary" block @click="addPP()">
                                Добавить
                            </v-btn>
                        </div>
                    </Grid>
                </div>
            </template>
        </Modal>
    </div>
</template>
<script>
import { mapState } from 'vuex'
// import { actionTypes } from '@/store/modules/auth'
import { globalMethods } from '@/mixins/globalMethods'
// import InfiniteScroll from 'vue-infinite-scroll';

export default {
    name: 'PricePage',
    // components: {
    // },
    mixins: [globalMethods],
    computed: {
        currentPage() {
            return this.page + 1
        },
        countItemsHtml() {
            let row = []
            return Object.entries(this.countItems).map(r => r.join(': ')).join('; ')
        }
    },
    created() {
        if (!this.checkAccess(this.$route.name)) {
            this.$router.push({ name: 'Main' });
        }
    },
    data() {
        return {
            findFields: [
                { label: 'Все', value: null },
                { label: 'Акция', value: 'stock' },
                { label: 'Наценка', value: 'extraCharge' },
            ],
            type: null,
            mp: null,
            arrPP: [],
            newPP: {
                id: '',
                price: '',
                mp: '',
                date: '',
                date_start: '',
            },
            marketplaces: [
                { label: 'Wb', value: 'wb' },
                { label: 'Ozon', value: 'ozon' },
                { label: 'Yandex', value: 'yandex' },
                { label: 'Sber', value: 'sber' },
            ],
            goodsId: null,
            page: 0,
            gIndex: 100,
            goodsList: [],
            goodsTotalList: [],
            items: [],
            isLoading: false,
            importData: {
                file: null,
                id: 'A',
                sber: 'B',
                ozon: 'C',
                yandex: 'D',
                wb: 'E',
            },
            countItems: {},
        }
    },
    mounted() {
        this.filter()
    },
    watch: {},
    methods: {
        isActive(date) {
            if (!date) {
                return 'уже действует';
            } else {
                const dateStart = this.$moment(date, 'YYYY-MM-DD_HH:mm');
                const currentDate = this.$moment(); // Текущая дата и время

                if (currentDate.isBefore(dateStart)) {
                    return this.$moment(date, 'YYYY-MM-DD_HH:mm').format('DD.MM.YYYY HH:mm')
                } else {
                    return 'уже действует';
                }
            }
        },
        selectAll(check) {
            if (check == true) {
                this.$set(this, 'arrPP', this.goodsList.reduce((result, item) => {
                    return [...result, item['_id']]
                }, []))
            } else {
                this.$set(this, 'arrPP', [])
            }
        },
        addPP() {
            let data = {
                ...this.newPP,
                date: this.$moment(this.newPP.date).format("YYYY-MM-DD_HH:mm"),
                date_start: this.newPP.date_start ? this.$moment(this.newPP.date_start).format("YYYY-MM-DD_HH:mm") : null,
            }
            let self = this
            this.sendRequest('POST', data, '/add_pp', {}, true, true)
                .then(response => {
                    this.goodsList = [this.newPP, ...this.goodsList]
                    this.newPP = {
                        id: '',
                        price: '',
                        mp: '',
                        date: '',
                        date_start: '',
                    }
                    this.$refs['addPricePromotion']._cancel()
                    // let chunk = this.arrayChunk(response, 100)
                    // this.$set(this, 'goodsTotalList', chunk)
                    // this.$set(this, 'goodsList', chunk[0])

                })
                .catch(err => {
                    this.$store.commit('setError', err)
                })
        },
        disabledTimeRange(date) {
            return this.$moment(date).unix() < Math.floor(new Date().setDate(new Date().getDate()) / 1000)
        },
        disabledRange(date) {
            return date < new Date(new Date().setDate(new Date().getDate() - 1))
        },
        filter() {
            let filter = {}
            if (/^[0-9]+$/.test(this.goodsId)) {
                filter.id = this.goodsId
            }
            if (!!this.mp) {
                filter.mp = this.mp
            }
            if (!!this.type) {
                filter.type = this.type
            }
            this.sendRequest('GET', filter, '/get_price_promotion', {}, true, true)
                .then(response => {
                    // response
                    let countItems = {
                        '<b>Всего</b>': response.length
                    }
                    response.forEach(i => {
                        let key = `<b>${i.mp}</b>`
                        if (!countItems[key]) {
                            countItems[key] = 0
                        }
                        ++countItems[key];
                    })

                    this.countItems = countItems
                    let chunk = this.arrayChunk(response, 100)
                    this.$set(this, 'goodsTotalList', chunk)
                    this.$set(this, 'goodsList', chunk[0])

                })
                .catch(err => {
                    this.$store.commit('setError', err)
                })
        },
        onFileSelected(event) {
            this.$set(this.importData, 'file', this.$refs.file.files[0])
        },
        changePage(action) {
            // {{currentPage}} из {{ goodsTotalList.length }}
            if (action == 'next') {
                let nextPage = this.page + 1
                if (nextPage <= this.goodsTotalList.length) {
                    this.$set(this, 'page', nextPage)
                } else {
                    this.$set(this, 'page', this.goodsTotalList.length)
                }
            } else {
                let prevPage = this.page - 1
                if (prevPage > 0) {
                    this.$set(this, 'page', prevPage)
                } else {
                    this.$set(this, 'page', 0)
                }
            }
            this.$set(this, 'goodsList', this.goodsTotalList[this.page])
        },
        arrayChunk(array, size) {
            const chunks = [];
            let index = 0;
            while (index < array.length) {
                chunks.push(array.slice(index, index + size));
                index += size;
            }
            return chunks;
        },
        loadMoreItems() {
            if (!this.isLoading) {
                this.isLoading = true;

                // Симуляция загрузки данных (замените эту часть на фактический код загрузки данных)
                setTimeout(() => {
                    this.$set(this, 'goodsList', [...this.goodsList, ...this.items.splice(this.gIndex, 100)])
                    this.$set(this, 'gIndex', this.gIndex + 100)
                    this.isLoading = false;
                }, 1000);
            }
        },
        changeGoods(value, id, items) {
            this.sendRequest('PUT', { value, id, items }, '/update_price_promotion', {}, true, true)
                .then(response => {
                    // this.$set(this, 'goodsList', response)
                })
                .catch(err => {
                    this.$store.commit('setError', err)
                })
        },
        deletePricePromotion() {
            if (this.arrPP.length > 0) {
                this.sendRequest('DELETE', { ids: this.arrPP }, '/delete_price_promotion', {}, true, true)
                    .then(response => {
                        let mList = this.goodsList.filter(i => !this.arrPP.includes(i['_id']))
                        this.$set(this, 'goodsList', mList)
                        this.$set(this, 'arrPP', [])
                    })
                    .catch(err => {
                        this.$set(this, 'buttonDisabled', false)
                        this.$store.commit('setError', err)
                    })
            }
        },

    },
}

</script>
<style lang="scss">
#price-page {
    table {
        height: 100%;

        thead {
            position: sticky;
            top: 0;
            z-index: 3;
        }
    }

    .table-contoll {
        overflow: unset;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 5px;
        height: 100% !important;
    }

    .pag {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .grid-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;

        >div {
            // flex: 1;
        }

        form {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 5px;
            align-items: flex-end;

            @media screen and (max-width: 576px) {
                grid-template-columns: 100%;
            }
        }
    }

    .import-dialog {
        padding-right: 40px;
    }

    .newline {
        td {
            background: #beffc8 !important;
        }
    }

    .button.delete {
        background: #a22020;
        color: #fff;
    }
}
</style>
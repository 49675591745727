<template>
    <div id="stocks-page">
        <Grid :column="1" :gap="20">
            <div class="submenu">
                <router-link :to="{ path: '/stocks/arithmetic' }" exact>Арифметика</router-link>
            </div>
            <router-view></router-view>
            <!-- :style="{ overflow: 'hidden' }" -->
            <template v-if="thisPage === 'Stocks'">
                <Block>
                    <Grid :column="3" :gap="5" :style="{ alignItems: 'flex-end', padding: '5px' }">
                        <div>
                            <input v-show="false" type="file" ref="file" accept=".xlsx" @change="onFileSelected" />
                            <v-btn variant="outlined" class="btn-w-border" @click="$refs['file'].click()">
                                <template v-if="specForm.file?.name">
                                    {{ specForm.file.name }}
                                </template>
                                <template v-else>
                                    Выберите файл
                                    <Icon width="24" height="24" viewBox="0 0 24 24" strockeWidth="2"
                                        :iconName="'attach'" />
                                </template>
                            </v-btn>
                        </div>
                        <SelectBlock v-model="mpSelect" :items="marketplaces" placeholder="Выберите">
                        </SelectBlock>
                        <SelectBlock v-model="listSelect" :items="lists" placeholder="Выберите лист">
                        </SelectBlock>
                        <div class="flex">
                            <div class="grid-row">
                                <span>Компенсация фикс:</span>
                                <v-text-field v-model.number="specForm.compensation_fix" autocomplete="off" outlined
                                    class="input"></v-text-field>
                            </div>
                            <div class="grid-row">
                                <span>Компенсация %:</span>
                                <v-text-field v-model.number="specForm.compensation" autocomplete="off" outlined
                                    class="input"></v-text-field>
                            </div>
                        </div>
                        <div class="grid-row">
                            <span>Колонка артикула:</span>
                            <v-text-field v-model="specForm.article" autocomplete="off" outlined
                                class="input"></v-text-field>
                        </div>
                        <div class="grid-row">
                            <span>Колонка плановой цены:</span>
                            <v-text-field v-model="specForm.price" autocomplete="off" outlined class="input"></v-text-field>
                        </div>
                        <v-btn type="submit" class="button mt-6" variant="tonal" color="primary" @click="parsing()">
                            Распарсить
                        </v-btn>
                    </Grid>
                </Block>
                <Block v-if="parseData !== null">
                    <Grid :column="3" :gap="5" :style="{ alignItems: 'center', padding: '5px' }">
                        <div class="grid-row">
                            <span>Наценка</span>
                            <v-checkbox color="#629F33" v-model="extraCharge"></v-checkbox>
                        </div>
                        <div class="grid-row">
                            <span>Продолжительность в часах</span>
                            <v-text-field v-model.number="inHour" autocomplete="off" outlined class="input"
                                :disabled="!extraCharge"></v-text-field>
                        </div>
                        <div class="grid-row">
                            <span>Процент наценки</span>
                            <v-text-field v-model.number="extraChargePercent" autocomplete="off" outlined class="input"
                                :disabled="!extraCharge"></v-text-field>
                        </div>
                    </Grid>
                </Block>
                <Block v-if="parseData !== null && (mpSelect === 'Yandex' || mpSelect === 'Sber')">
                    <Grid :column="2" :gap="5" :style="{ alignItems: 'center', padding: '5px' }">
                        <div class="grid-row">
                            <div>
                                <h3>Зачеркнутая цена</h3>
                                <span>Значение</span>
                                <v-text-field v-model.number="yandex_old_price" autocomplete="off" outlined
                                    class="input"></v-text-field>
                                <span>Колонка</span>
                                <v-text-field v-model.number="yandex_old_price_column" autocomplete="off" outlined
                                    class="input"></v-text-field>
                            </div>
                        </div>
                        <div class="grid-row">
                            <h3>Цена по акции</h3>
                            <span>Колонка значения</span>
                            <v-text-field v-model.number="yandex_price" autocomplete="off" outlined
                                class="input"></v-text-field>
                            <span>Колонка</span>
                            <v-text-field v-model.number="yandex_price_column" autocomplete="off" outlined
                                class="input"></v-text-field>
                        </div>
                    </Grid>
                </Block>
                <Block v-if="parseData !== null">
                    <Grid :column="3" :gap="5" :style="{ alignItems: 'center', padding: '5px' }">
                        <div>
                            DS: {{ avgDS }}% | ПП: {{ ppDS }}<br>
                            DK: {{ avgDK }}% | ПП: {{ ppDK }}<br>
                            DD: {{ avgDD }}% | ПП: {{ ppDD }}
                        </div>
                        <div :style="{ display: 'flex', justifyContent: 'flex-end', gap: '5px' }">
                            <div>
                                <div>С</div>
                                <date-picker lang="ru" type="datetime" v-model="date2" value-type="date"
                                    :format="'DD.MM.YYYY HH:mm'" :default-value="new Date()" :show-second="false"
                                    :show-minute="true" :minute-step="1" :disabled-date="disabledRange"
                                    :disabled-time="disabledTimeRange"></date-picker>
                                <div>До</div>
                                <date-picker lang="ru" type="datetime" v-model="date" value-type="date"
                                    :format="'DD.MM.YYYY HH:mm'" :default-value="new Date()" :show-second="false"
                                    :show-minute="true" :minute-step="1" :disabled-date="disabledRange"
                                    :disabled-time="disabledTimeRange"></date-picker>
                            </div>
                            <div class="grid-row">
                                <span>Колонка "ДА":</span>
                                <v-text-field v-model.number="yes" autocomplete="off" outlined class="input"></v-text-field>
                            </div>
                        </div>
                        <v-btn type="submit" class="button mt-6" variant="tonal" color="primary" @click="setPrice()">
                            Установить цену
                        </v-btn>
                    </Grid>
                </Block>
                <TableBlock v-if="parseData !== null">
                    <template v-slot:head>
                        <tr>
                            <th>
                                <v-checkbox color="#629F33" @change="selectAll"></v-checkbox>
                            </th>
                            <th>#</th>
                            <th>Название</th>
                            <th>Остаток</th>
                            <th>Закупочная цена</th>
                            <th>Моя цена</th>
                            <th>Предлагаемая цена</th>
                            <th>Коммиссия с компенсацией</th>

                            <th>Коммиссия МП</th>
                            <th>Логистика</th>
                            <!-- <th>ПП WB</th>
                        <th>ПП Сбер</th>
                        <th>ПП Яндекс</th> -->
                            <th>{{ mpSelect }} %</th>
                            <th>ПП {{ mpSelect }}</th>
                            <!-- <th>WB %</th>
                        <th>Сбер %</th>
                        <th>Яндекс %</th> -->
                        </tr>
                    </template>
                    <template v-slot:body>
                        <tr v-for="info of parseData" :key="'ng-' + info.id" :class="getClass(info)">
                            <!--                        ppOzon,-->
                            <!--                        ppWb,-->
                            <!--                        ppSber,-->
                            <!--                        ppYandex,-->
                            <!--                        parcentProfiteOzon,-->
                            <!--                        parcentProfiteWb,-->
                            <!--                        parcentProfiteSber,-->
                            <!--                        parcentProfiteYandex,-->
                            <td>
                                <v-checkbox color="#629F33" v-model="selectItems" :value="info.id"></v-checkbox>
                            </td>
                            <td>{{ info.id }}</td>
                            <td>{{ info.name }}</td>
                            <td>{{ info.stock }}</td>
                            <td>{{ info.zacup }}₽</td>
                            <td>{{ info.myPrice?.[mpSelect.toLowerCase()] != '—' ?
                                Math.ceil(info.myPrice[mpSelect.toLowerCase()]) : '—' }}₽</td>
                            <td>{{ info.price }}₽
                                <template v-if="info.sale_wb === true">
                                    <v-text-field v-model.number="info.price" autocomplete="off" outlined
                                        class="input"></v-text-field>
                                </template>
                            </td>
                            <td>{{ info[`wCompens${mpSelect}`] }}%</td>
                            <td>{{ info?.commissions[mpSelect.toLowerCase()] || 0 }}%</td>
                            <td>{{ info?.logistic?.[mpSelect.toLowerCase()] || 0 }}₽</td>
                            <td>{{ info[`parcentProfite${mpSelect}`] }}%</td>
                            <td>{{ info[`pp${mpSelect}`] }}₽</td>
                            <!-- <th>{{ info.parcentProfiteWb }}%</th>
                        <th>{{ info.parcentProfiteSber }}%</th>
                        <th>{{ info.parcentProfiteYandex }}%</th> -->
                        </tr>
                    </template>
                </TableBlock>
            </template>
        </Grid>
    </div>
</template>
<script>
import { mapState } from 'vuex'
// import { actionTypes } from '@/store/modules/auth'
import { globalMethods } from '@/mixins/globalMethods'

export default {
    name: 'GoodsPage',
    // components: {
    // },
    mixins: [globalMethods],
    computed: {
        thisPage() {
            return this.$route.name
        },
        avgDS() {
            if (this.parseData) {
                // info[`parcentProfite${mpSelect}`]
                let s = []
                this.parseData.filter(p => parseInt(p.id) < 30000).map(p => {
                    if (this.selectItems.includes(p.id)) {
                        s.push(parseFloat(p[`parcentProfite${this.mpSelect}`]))
                    }
                })
                const sum = s.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                const average = sum / s.length;
                return average ? average.toFixed(2) : 0
                // return (this.selectItems.length * 100 / this.parseData.length).toFixed(2)
            } else {
                return 0
            }
        },
        ppDS() {
            if (this.parseData) {
                // info[`parcentProfite${mpSelect}`]
                let s = 0
                this.parseData.filter(p => parseInt(p.id) < 30000).map(p => {
                    if (this.selectItems.includes(p.id)) {
                        s += parseInt(p.stock) * parseFloat(p[`pp${this.mpSelect}`])
                    }
                })
                return s.toFixed(2)
            } else {
                return 0
            }
        },
        avgDK() {
            if (this.parseData) {
                // info[`parcentProfite${mpSelect}`]
                let s = []
                this.parseData.filter(p => parseInt(p.id) >= 30000 && parseInt(p.id) < 32001).map(p => {
                    if (this.selectItems.includes(p.id)) {
                        s.push(parseFloat(p[`parcentProfite${this.mpSelect}`]))
                    }
                })
                const sum = s.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                const average = sum / s.length;
                return average ? average.toFixed(2) : 0
                // return (this.selectItems.length * 100 / this.parseData.length).toFixed(2)
            } else {
                return 0
            }
        },
        ppDK() {
            if (this.parseData) {
                // info[`parcentProfite${mpSelect}`]
                let s = 0
                this.parseData.filter(p => parseInt(p.id) >= 30000 && parseInt(p.id) < 32001).map(p => {
                    if (this.selectItems.includes(p.id)) {
                        s += parseInt(p.stock) * parseFloat(p[`pp${this.mpSelect}`])
                    }
                })
                return s.toFixed(2)
            } else {
                return 0
            }
        },
        avgDD() {
            if (this.parseData) {
                // info[`parcentProfite${mpSelect}`]
                let s = []
                this.parseData.filter(p => parseInt(p.id) >= 32001).map(p => {
                    if (this.selectItems.includes(p.id)) {
                        s.push(parseFloat(p[`parcentProfite${this.mpSelect}`]))
                    }
                })
                const sum = s.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                const average = sum / s.length;
                return average ? average.toFixed(2) : 0
                // return (this.selectItems.length * 100 / this.parseData.length).toFixed(2)
            } else {
                return 0
            }
        },
        ppDD() {
            if (this.parseData) {
                // info[`parcentProfite${mpSelect}`]
                let s = 0
                this.parseData.filter(p => parseInt(p.id) >= 32001).map(p => {
                    if (this.selectItems.includes(p.id)) {
                        s += parseInt(p.stock) * parseFloat(p[`pp${this.mpSelect}`])
                    }
                })
                return s.toFixed(2)
            } else {
                return 0
            }
        },
    },
    data() {
        return {
            yandex_old_price: null,
            yandex_old_price_column: null,
            yandex_price: null,
            yandex_price_column: null,
            inHour: null,
            extraCharge: false,
            extraChargePercent: null,
            yes: '',
            selectItems: [],
            parseData: null,
            specForm: {
                file: null,
                article: 'A',
                price: 'B',
                compensation: 0,
                compensation_fix: 0,
                logistic: 0
            },
            mpSelect: 'Wb',
            marketplaces: [
                { label: 'Wb', value: 'Wb' },
                { label: 'Ozon', value: 'Ozon' },
                { label: 'Yandex', value: 'Yandex' },
                { label: 'Sber', value: 'Sber' },
            ],
            listSelect: null,
            lists: [
                { label: '1', value: '0' },
                { label: '2', value: '1' },
                { label: '3', value: '2' },
            ],
            date: '',
            date2: new Date(),
        }
    },
    watch: {                
        'yes': function (val) {
            if (/^[a-z]+$/i.test(val)) {
                this.$set(this.specForm, 'price', val.toUpperCase())
            } else {
                this.$set(this.specForm, 'price', 'Z')
            }
        },
        'specForm.price': function (val) {
            if (/^[a-z]+$/i.test(val)) {
                this.$set(this.specForm, 'price', val.toUpperCase())
            } else {
                this.$set(this.specForm, 'price', 'B')
            }
        },
        'specForm.article': function (val) {
            if (/^[a-z]+$/i.test(val)) {
                this.$set(this.specForm, 'article', val.toUpperCase())
            } else {
                this.$set(this.specForm, 'article', 'A')
            }
        },
        'yandex_old_price_column': function (val) {
            if (/^[a-z]+$/i.test(val)) {
                this.$set(this, 'yandex_old_price_column', val.toUpperCase())
            } else {
                this.$set(this, 'yandex_old_price_column', 'Z')
            }
        },
        'yandex_price_column': function (val) {
            if (/^[a-z]+$/i.test(val)) {
                this.$set(this, 'yandex_price_column', val.toUpperCase())
            } else {
                this.$set(this, 'yandex_price_column', 'Z')
            }
        },
        'yandex_price': function (val) {
            if (/^[a-z]+$/i.test(val)) {
                this.$set(this, 'yandex_price', val.toUpperCase())
            } else {
                this.$set(this, 'yandex_price', 'Z')
            }
        },
    },
    methods: {
        setPrice() {
            let items = this.parseData.reduce((result, item) => {
                if (this.selectItems.includes(item.id)) {
                    return [...result, { id: item.id, price: item.price }]
                } else {
                    return result
                }
            }, [])

            let file = this.specForm.file;

            let formData = new FormData();
            formData.append("file", file);
            formData.append("items", JSON.stringify(items));
            formData.append("yes", this.yes);
            formData.append("yandex_old_price", this.yandex_old_price);
            formData.append("yandex_price", this.yandex_price);
            formData.append("yandex_price_column", this.specForm.price);
            formData.append("yandex_price_column2", this.yandex_price_column);
            formData.append("yandex_old_price_column", this.yandex_old_price_column);
            formData.append("article", this.specForm.article);

            formData.append("date", this.$moment(this.date).format('YYYY-MM-DD_HH:mm'));
            if (this.date2) {
                formData.append("date2", this.$moment(this.date2).format('YYYY-MM-DD_HH:mm'));
            }
            formData.append("mp", this.mpSelect);
            formData.append("list", this.listSelect || 0);

            if (
                this.extraCharge === true
                && this.inHour
                && this.extraChargePercent
            ) {
                formData.append("extraCharge", 1);
                formData.append("inHour", this.inHour);
                formData.append("extraChargePercent", this.extraChargePercent);
            }

            this.sendRequest('POST', formData, '/set_price', {
                "content-type": "multipart/form-data",
            }, true, true)
                .then(response => {
                    // axios.defaults.baseURL = process.env.NODE_ENV !== 'production' ? 'http://localhost:3301' : 'http://monitor.dambul-shop.ru'
                    location.href = "https://monitor.dambul-shop.ru/api/open/" + response.file;
                })
                .catch(err => {
                    this.$store.commit('setError', err)
                })
            // this.sendRequest('POST', { date: this.$moment(this.date).format('YYYY-MM-DD_HH:mm'), mp: this.mpSelect, items }, '/set_price', {}, true, true)
            //     .then(response => {
            //         alert('цены установлены')
            //     })
            //     .catch(err => {
            //         this.$store.commit('setError', err)
            //     })

        },
        selectAll(check) {
            if (check == true) {
                this.$set(this, 'selectItems', this.parseData.reduce((result, item) => {
                    return [...result, '' + item.id]
                }, []))
            } else {
                this.$set(this, 'selectItems', [])
            }
        },
        disabledTimeRange(date) {
            return this.$moment(date).unix() < Math.floor(new Date().setDate(new Date().getDate()) / 1000)
        },
        disabledRange(date) {
            return date < new Date(new Date().setDate(new Date().getDate() - 1))
        },
        getCustomDay(date) {
            const daysOfWeek = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];
            let i = new Date(date).getDay(date)
            return daysOfWeek[i]
        },
        onFileSelected(event) {
            this.$set(this.specForm, 'file', this.$refs.file.files[0])
        },
        getClass(n) {
            if (n.sale_wb == true) {
                return 'yellow'
            } else if (n.stock <= 0) {
                return 'blue'
            } else {
                return n[`pp${this.mpSelect}`] < 0 ? 'red' : 'green'
            }
        },
        parsing() {
            if (this.specForm.article && this.specForm.price && this.specForm.file) {
                let file = this.specForm.file;

                let formData = new FormData();
                formData.append("file", file);
                formData.append("for_parse", true);

                formData.append("article", this.specForm.article);
                formData.append("price", this.specForm.price);
                formData.append("compensation", this.specForm.compensation);
                formData.append("compensation_fix", this.specForm.compensation_fix);
                formData.append("list", this.listSelect || 0);
                formData.append("logistic", this.specForm.logistic || 0);
                this.sendRequest('POST', formData, '/parsing_file', {
                    "content-type": "multipart/form-data"
                }, true, true)
                    .then(response => {
                        this.$set(this, 'parseData', response)
                    })
                    .catch(err => {
                        this.$store.commit('setError', err)
                    })
            }
        },
    }
}
</script>
<style lang="scss">
#stocks-page {
    .table-scroll {
        height: 100vh;

        thead {
            position: sticky;
            top: 0;
            z-index: 3;
        }

        tbody {
            tr {
                &.red {
                    td {
                        background: rgba(159, 51, 51, 0.376);
                    }
                }

                &.green {
                    td {
                        background: rgba(98, 159, 51, 0.376);
                    }
                }

                &.blue {
                    td {
                        background: rgba(51, 114, 159, 0.376);
                    }
                }

                &.yellow {
                    td {
                        background: rgba(237, 238, 38, 0.78)
                    }
                }
            }
        }
    }

    .btn-w-border {
        max-width: 200px !important;
        overflow: hidden;
    }

    .flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 5px;
    }
}
</style>
<template>
    <Grid :column="1" :gap="20">
        <Block padding="20">
            <v-form @submit.prevent="onSubmit" class="new-manager-form">
                <v-text-field v-model="newManager.email" autocomplete="off" outlined class="input" placeholder="Email"
                    :error-messages="errors.email" :error="!!errors.email"></v-text-field>
                <v-text-field v-model="newManager.name" autocomplete="off" outlined class="input" placeholder="Имя"
                    :error-messages="errors.name" :error="!!errors.name"></v-text-field>
                <v-text-field v-model="newManager.password" autocomplete="off" outlined class="input" placeholder="Пароль"
                    :error-messages="errors.password" :error="!!errors.password"></v-text-field>
                <!-- <v-select :value="newManager.role" class="rounded-lg custom__select" :items="roleTypes"
                     placeholder="Роль"
                    append-icon="mdi-chevron-down" outlined dense required></v-select> -->
                <!-- <v-select v-model="selectedItem" :items="items" label="Select item"
                    ></v-select> -->
                <v-radio-group v-model="newManager.role">
                    <v-radio label="Начальник производства" :value="2"></v-radio>
                    <v-radio label="Складовщик" :value="3"></v-radio>
                    <v-radio label="Контент менеджер" :value="4"></v-radio>
                </v-radio-group>
                <v-btn type="submit" class="button mt-6" variant="tonal" color="primary" :disabled="buttonDisabled">
                    Добавить
                </v-btn>
            </v-form>
        </Block>
        <Block padding="20">
            <v-form @submit.prevent="changePassword" class="new-manager-form">
                <v-text-field v-model="newPasword" autocomplete="off" outlined class="input" placeholder="Новый пароль"
                    :error-messages="errors.newPasword" :error="!!errors.newPasword"></v-text-field>
                <v-btn type="submit" class="button mt-6" variant="tonal" color="primary"
                    :disabled="!newPasword || newPasword.length < 6">
                    Установить новый пароль
                </v-btn>
            </v-form>
        </Block>
        <Block :style="{ overflow: 'hidden' }">
            <TableBlock>
                <template v-slot:head>
                    <tr>
                        <th>#</th>
                        <th>Имя</th>
                        <th>Email</th>
                        <th>Роль</th>
                        <th></th>
                        <th></th>
                    </tr>
                </template>
                <template v-slot:body>
                    <tr v-for="(m, ind) of managerList" :key="'m' + m.id">
                        <td>{{ ind + 1 }}</td>
                        <td>{{ m.name }}</td>
                        <td>{{ m.email }}</td>
                        <td>{{ dictionary[m.role] }}</td>
                        <td>
                            <button class="ghost btn-key" @click="updateStaff('password', 'new', m.id, ind)">
                                <Icon width="20" height="20" viewBox="0 0 24 24" strockeWidth="2" :iconName="'key'" />
                            </button>
                        </td>
                        <td class="td-btn">
                            <Icon iconName="trash" width="12" height="13" viewBox="0 0 16 16" strockeWidth="1"
                                stroke="#a22020" @click="deleteManager(m.id, int)" />
                        </td>
                    </tr>
                </template>
            </TableBlock>
            <Modal ref="confirmation">
                <template v-slot:header>
                    {{ confirmationData.question }}
                </template>
                <template v-slot:body>
                    <div class="center" v-html="confirmationData.message"></div>
                </template>
                <template v-slot:footer>
                    <button class="ghost black" @click="$refs['confirmation']._cancel()">
                        Отмена
                    </button>
                    <v-btn type="submit" class="button mt-6 error" variant="tonal" color="primary"
                        @click="$refs['confirmation']._confirm()">
                        Продолжить
                    </v-btn>
                </template>
            </Modal>
        </Block>
    </Grid>
</template>
<script>
import { mapState } from 'vuex'
// import { actionTypes } from '@/store/modules/auth'
import { globalMethods } from '@/mixins/globalMethods'
export default {
    name: 'ManagersPage',
    // components: {
    // },

    mixins: [globalMethods],
    created() {
        if (!this.checkAccess(this.$route.name)) {
            this.$router.push({ name: 'Main' });
        }
    },
    mounted() {
        this.sendRequest('GET', {}, '/get_manegers', {}, true, true)
            .then(response => {
                this.$set(this, 'managerList', response)
            })
            .catch(err => {
                this.$store.commit('setError', err)
            })
    },
    data() {
        return {
            newPasword: '',
            confirmationData: {
                question: '',
                message: '',
                confirmBtn: ''
            },
            dictionary: {
                '2': 'Начальник производства',
                '3': 'Складовщик',
                '4': 'Контент менеджер',
            },
            roleTypes: [
                { value: 2, text: 'Начальник производства' },
                { value: 3, text: 'Складовщик' },
                { value: 4, text: 'Контент менеджер' },
            ],
            newManager: {
                email: '',
                name: '',
                password: '',
                role: 2
            },
            buttonDisabled: false,
            errors: {
                email: null,
                name: null,
            },
            managerList: [],
            selectedItem: null,
            items: [
                { text: 'Item 1', value: 1 },
                { text: 'Item 2', value: 2 },
                { text: 'Item 3', value: 3 },
                { text: 'Item 4', value: 4 },
                { text: 'Item 5', value: 5 },
            ],
        }
    },
    computed: {
        ...mapState({
            user: state => state.user,
            //   isSubmitting: state => state.auth.isSubmitting,
            //   validationErrors: state => state.auth.validationErrors
        }),
    },
    watch: {

    },
    methods: {
        changePassword() {
            console.log(11111111)
            console.log(11111111)
            console.log(11111111)
            console.log(11111111)
            console.log(this.user)
            this.updateStaff('password', this.newPasword, this.user['_id'])
            // F141C8Sf
        },
        updateStaff(option, value, user, ind) {
            let confirmBtn = '',
                question = '',
                message = ''
            if (option == 'state') {
                if (value == 1) {
                    confirmBtn = 'blocked'
                    question = 'Блокировка сотрудника'
                    message = 'Вы действительно хотите заблокировать сотрудника'
                } else {
                    confirmBtn = 'unblocked'
                    question = 'Разблокировка сотрудника'
                    message = 'Вы действительно хотите разблокировать сотрудника'
                }
            }
            if (option == 'password' && value === 'new') {
                confirmBtn = 'change'
                question = 'Замена пароля'
                message = `Вы действительно хотите изменить пароль сотрудника
        <div class="color-red">Это действие является постоянным и не может быть отменено!</div>`
            } else if (option == 'password' && value !== 'new') {
                confirmBtn = 'change'
                question = 'Замена пароля'
                message = `Вы действительно хотите изменить пароль
        <div class="color-red">Это действие является постоянным и не может быть отменено!</div>`


            }
            this.$set(this, 'confirmationData', {
                question: question,
                message: message,
                confirmBtn: confirmBtn
            })
            this.showModal('confirmation').then((result) => {
                if (result) {
                    let obj = {
                        id: user,
                        option,
                        value
                    }

                    this.sendRequest('PUT', obj, '/update_user', {}, true, true)
                        .then(response => {
                            if (option == 'state') {
                                if (ind) {
                                    this.$set(this.staffs[ind], option, value)
                                }
                            } else if (option == 'password') {
                                if (value == 'new') {
                                    this.$store.commit('setNotification', `Новый пароль сотрудника: <b>${response}</b>`)
                                } else {
                                    this.$store.commit('setNotification', `Пароль изменен на <b>${response}</b>`)
                                    this.newPasword = ''
                                }
                            }
                        })
                        .catch(err => {
                            this.$store.commit('setError', err)
                        })
                }
            })
        },
        deleteManager(id, int) {
            this.checkId({ id })
                .then(data => {
                    this.sendRequest('DELETE', data, '/delete_maneger', {}, true, true)
                        .then(response => {
                            let mList = [...this.managerList]
                            mList.splice(int, 1)
                            this.$set(this, 'managerList', mList)
                        })
                        .catch(err => {
                            this.$set(this, 'buttonDisabled', false)
                            this.$store.commit('setError', err)
                        })
                })
                .catch(err => {
                    this.$store.commit('setError', err)
                })
        },
        onSubmit() {
            this.$set(this, 'buttonDisabled', true)
            this.checkEmail(this.newManager)
                .then(this.checkName)
                .then(data => {
                    this.sendRequest('POST', data, '/add_maneger', {}, true, true)
                        .then(response => {
                            this.$set(this, 'buttonDisabled', false)
                            this.$set(this, 'managerList', [...this.managerList, { ...data, id: response.id, operations: [], role: 2 }])

                            this.$set(this, 'newManager', {
                                email: '',
                                name: '',
                                password: '',
                            })

                        })
                        .catch(err => {
                            this.$set(this, 'buttonDisabled', false)
                            this.$store.commit('setError', err)
                        })
                })
                .catch(err => {
                    this.$store.commit('setError', err)
                })
        }
    }
}
</script>
<style lang="scss">
.new-manager-form {
    display: flex;
    align-items: center;
    gap: 20px;

    @media screen and (max-width: 560px) {
        display: grid;
        row-gap: 5px;
    }

    .v-input__slot {
        margin-bottom: 0 !important;
    }

    .v-text-field__details {
        display: none !important;
        border-radius: 30px !important;
    }

    button {
        height: 56px !important;
    }
}

.td-btn {
    svg {
        cursor: pointer;
    }
}

.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)>.v-input__control>.v-input__slot,
.v-text-field.v-text-field--enclosed .v-text-field__details {
    padding: 0 12px;
    // height: 56px;
    // display: flex;
    // align-items: center;
}
</style>
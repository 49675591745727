<template>
    <div id="fbo-page">
        <Grid :column="1" :gap="20">
            <Block padding="20">
                <div class="new-manager-form">
                    <!-- <v-text-field v-model="goodsID" autocomplete="off" outlined class="input"
                        placeholder="ID товара"></v-text-field> -->
                    <v-app class="clear-style">
                        <v-autocomplete label="Товар" :items="items" item-value="value" item-text="title" outlined
                            class="input" autocomplete="on" @change="selected" :loading="isLoading"
                            :search-input.sync="search" clearable></v-autocomplete>
                    </v-app>
                    <v-btn type="submit" class="button mt-6" variant="tonal" color="primary" @click="find">
                        <!-- :disabled="buttonDisabled" -->
                        Найти
                    </v-btn>
                </div>
            </Block>
            <Block padding="20" v-if="goods">
                <v-form @submit.prevent="createdTask" class="new-manager-form">
                    <v-text-field v-model="newTask.count" autocomplete="off" outlined class="input"
                        placeholder="Количество"></v-text-field>
                    <v-text-field v-model="newTask.price" autocomplete="off" outlined class="input"
                        placeholder="ЗП оператора"></v-text-field>
                    <v-text-field v-model="newTask.mp" autocomplete="off" outlined class="input"
                        placeholder="МП"></v-text-field>
                    <v-btn type="submit" class="button mt-6" variant="tonal" color="primary"
                        :disabled="buttonCreatedDisabled">
                        Добавить задачу
                    </v-btn>
                </v-form>
            </Block>
        </Grid>
        <Modal ref="success">
            <template v-slot:header>
                Задача добавлена
            </template>
        </Modal>
    </div>
</template>
<script>
import { mapState } from 'vuex'
// import { actionTypes } from '@/store/modules/auth'
import { globalMethods } from '@/mixins/globalMethods'
import { debounce } from 'lodash'
export default {
    name: 'FBOPage',
    // components: {
    // },
    mixins: [globalMethods],
    created() {
        if (!this.checkAccess('goods')) {
            this.$router.push({ name: 'Main' });
        }
    },
    mounted() {
        this.searchGoods = debounce(this.fetchData, 200)
    },
    data() {
        return {
            searchGoods: null,
            search: '',
            isLoading: false,
            items: [],
            goods: null,
            goodsID: null,
            buttonDisabled: false,
            buttonCreatedDisabled: false,
            newTask: {
                goods_id: null,
                count: null,
                name: null,
                price: null,
                total: null,
                description: null,
                photo: null,
                status: 1,
                cost_price: null,
                date: null,
                manager: '',
                stock: 0,
                mp: null
            }
        }
    },
    computed: {
        ...mapState({
            //   isSubmitting: state => state.auth.isSubmitting,
            //   validationErrors: state => state.auth.validationErrors
        }),
    },
    watch: {
        goodsID: function (val, oldVal) {
            if (oldVal != null && val == null) {
                this.goods = null
                this.newTask = {
                    goods_id: null,
                    count: null,
                    name: null,
                    price: null,
                    total: null,
                    description: null,
                    photo: null,
                    status: 1,
                    cost_price: null,
                    date: null,
                    manager: '',
                    stock: 0,
                    mp: null
                }
            }
        },
        search: function (val) {
            if (val.length > 3) {
                this.searchGoods()
            }
        }
    },
    methods: {
        selected(id) {
            this.goodsID = id
        },
        fetchData() {
            try {
                if (this.search.length > 3) {
                    this.isLoading = true;
                    // Здесь выполняется запрос на сервер для поиска данных
                    this.sendRequest('GET', { term: this.search }, '/search_goods_by_name', {}, true, true)
                        .then(response => {
                            this.items = response.map(i => {
                                return {
                                    title: i.id+' | '+i.name,
                                    value: i.id,
                                }
                            })

                        })
                        .catch(err => {
                            this.$store.commit('setError', err)
                        })
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                this.isLoading = false;
            }
        },
        createdTask() {
            this.$set(this, 'buttonCreatedDisabled', true)
            if (!!this.newTask?.count && !!this.newTask?.price && !!this.newTask?.mp) {
                this.$refs['success'].show({}).then((result, label) => { })
                let task = {
                    ...this.newTask,
                    total: parseInt(this.newTask?.count) * parseFloat(this.newTask?.price),
                }
                this.sendRequest('POST', task, '/created_task', {}, true, true)
                    .then(response => {
                        this.$set(this, 'buttonCreatedDisabled', false)
                        // this.$set(this, 'goods', null)
                        // this.$set(this, 'goodsID', null)
                        // this.$set(this, 'search', '')
                        // this.$set(this, 'items', [])
                        this.$set(this, 'newTask', {
                            ...this.newTask,
                            count: null,
                            price: null,
                            mp: null
                        })
                        this.$refs['success'].show({}).then((result, label) => { })

                    })
                    .catch(err => {
                        this.$set(this, 'buttonCreatedDisabled', false)
                        this.$store.commit('setError', err)
                    })
            } else {
                this.$store.commit('setError', 'Заполните все поля')
            }
        },
        find() {
            this.$set(this, 'buttonDisabled', true)
            let gID = '' + this.goodsID

            this.sendRequest('GET', { goods_id: gID.trim() }, '/get_goods_by_id', {}, true, true)
                .then(response => {
                    this.$set(this, 'buttonDisabled', false)
                    if (response?.length > 0) {
                        let goods = response[0]
                        this.$set(this, 'goods', goods)
                        this.$set(this, 'newTask', {
                            goods_id: goods.id,
                            count: null,
                            name: goods.name,
                            price: null,
                            total: null,
                            description: goods.description,
                            photo: goods.photo,
                            status: 1,
                            cost_price: goods.cost_price,
                            date: this.$moment().format(),
                            manager: '',
                            stock: null,
                            fbo: true,
                        })
                    } else {
                        this.$store.commit('setError', 'Товар не найден')
                        this.$set(this, 'goods', null)
                    }
                    // let arr1 = [],
                    //     arr2 = []
                    // response.map(r => {
                    //     if (
                    //         !r.min_count_for_transfer &&
                    //         !r.count_for_task &&
                    //         !r.profit_avito &&
                    //         !r.profit_wb &&
                    //         !r.profit_ozon
                    //     ) {
                    //         arr1.push(r)
                    //     } else {
                    //         arr2.push(r)
                    //     }
                    // })
                    // // this.$set(this, 'pageCount', Math.ceil(response.count / 100))                    
                    // this.$set(this, 'goodsList', [...arr1, ...arr2])
                })
                .catch(err => {
                    this.$set(this, 'buttonDisabled', false)
                    this.$store.commit('setError', err)
                })
        }
    }
}
</script>
<style lang="scss">
.clear-style {
    // height: 56px;
    width: 100%;

    .v-application--wrap {
        min-height: unset;
    }
}
</style>
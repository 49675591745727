<template>
    <div id="goods-page">
        <Grid :column="1" :gap="20">
            <div class="submenu">
                <router-link :to="{ path: '/goods/fbo' }" exact>FBO</router-link>
            </div>
            <Block padding="5">
                <Grid :column="2" :gap="20">
                    <div>
                        <Heading :level="ind + 1" :text="h?.meta?.name" v-for="(h, ind) of this.$route.matched"
                            :key="'h-' + h.name" />
                    </div>
                </Grid>
            </Block>
            <router-view></router-view>
            <template v-if="this.$route.name == 'Goods'">
                <Block padding="5">
                    <div class="grid-row">
                        <form @submit.prevent="filter()">
                            <div>
                                <span>ID товара:</span>
                                <v-text-field v-model.number="goods_id" autocomplete="off" outlined
                                    class="input"></v-text-field>
                            </div>
                            <v-btn type="submit" class="button mt-6" variant="tonal" color="primary">
                                Найти
                            </v-btn>
                        </form>
                        <v-pagination :total-visible="5" v-model="currentPage" :length="totalPages"></v-pagination>
                    </div>
                </Block>
                <input v-show="false" type="file" ref="goodsimg" v-on:change="uploadImg()" />
                <Block :style="{ overflow: 'hidden' }">
                    <!-- <div class="table-contoll">
                    <div></div>
                    <span><v-checkbox color="#629F33" label="Только для монитора"
                            v-model.number="forTask"></v-checkbox></span>
                    <span>страница {{ thisPage }} из {{ pageCount }}</span>
                    <v-btn variant="outlined" class="btn-w-border" @click="changePage('prev')">
                        <Icon width="24" height="24" viewBox="0 0 24 24" strockeWidth="2.2" :iconName="'chevron-left'" />
                    </v-btn>
                    <v-btn variant="outlined" class="btn-w-border" @click="changePage('next')">
                        <Icon width="24" height="24" viewBox="0 0 24 24" strockeWidth="2.2" :iconName="'chevron-right'" />
                    </v-btn>
                </div> -->
                    <TableBlock>
                        <template v-slot:head>
                            <tr v-if="!isMobyle">
                                <!-- <th></th> -->
                                <th>#</th>
                                <th class="max-w">Название | Картинка | Описание</th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </template>
                        <template v-slot:body>
                            <tr v-for="(g, ind) of goodsList" :key="'g-' + g.id">
                                <!-- <td>
                                <Icon width="24" height="24" viewBox="0 0 24 24" strockeWidth="2.2" :iconName="'trash'"
                                    @click="deleteGoods(g.id, ind)" />
                            </td> -->
                                <td>
                                    {{ g.id }}
                                    <div class="trash-div">
                                        <Icon width="24" height="24" viewBox="0 0 24 24" strockeWidth="2.2"
                                            :iconName="'trash'" stroke="#a22020" @click="deleteGoods(g.id, ind)" />
                                    </div>
                                </td>
                                <td class="max-w">
                                    <h3>{{ g.name }}</h3>
                                    <template v-if="g.photo">
                                        <img :src="`/public/upload/img/${g.photo}`"
                                            @click="selectGoods = g.id; $refs['goodsimg'].click()">
                                    </template>
                                    <template v-else>
                                        <div class="no-photo" @click="selectGoods = g.id; $refs['goodsimg'].click()">
                                            <Icon width="24" height="24" viewBox="0 0 24 24" strockeWidth="2.2"
                                                iconName="photo" />
                                        </div>
                                    </template>
                                    <div class="field">
                                        <span class="caption">Описание</span>
                                        <v-textarea v-model="g.description" outlined
                                            @change="changeGoods($event, g.id, 'description')"></v-textarea>
                                    </div>
                                </td>
                                <td>
                                    <div class="start">
                                        <div class="field">
                                            <span class="caption">Мин кол-во для<br>задачи</span>
                                            <v-text-field v-model.number="g.min_count_for_transfer" autocomplete="off"
                                                outlined class="input"
                                                @change="changeGoods($event, g.id, 'min_count_for_transfer')"></v-text-field>
                                        </div>
                                        <!-- <div class="field">
                                    <span class="caption">Логистика (WB)</span>
                                    <v-text-field v-model.number="g.logistics_wb" autocomplete="off" outlined class="input"
                                        @change="changeGoods($event, g.id, 'logistics_wb')"></v-text-field>
                                </div> -->
                                        <div class="field">
                                            <span class="caption">Налог %<br>(WB)</span>
                                            <v-text-field v-model.number="g.tax_wb" autocomplete="off" outlined
                                                class="input"
                                                @change="changeGoods($event, g.id, 'tax_wb')"></v-text-field>
                                        </div>
                                        <!-- <div class="field">
                                    <span class="caption">Комиссия % (WB)</span>
                                    <v-text-field v-model.number="g.commission_wb" autocomplete="off" outlined class="input"
                                        @change="changeGoods($event, g.id, 'commission_wb')"></v-text-field>
                                </div> -->
                                        <div class="field">
                                            <span class="caption">Profit<br>(WB)</span>
                                            <v-text-field v-model.number="g.profit_wb" autocomplete="off" outlined
                                                class="input"
                                                @change="changeGoods($event, g.id, 'profit_wb')"></v-text-field>
                                        </div>
                                        <div class="field">
                                            <span class="caption">Этикетка<br>(WB)</span>
                                            <v-text-field v-model.number="g.label_wb" autocomplete="off" outlined
                                                class="input"
                                                @change="changeGoods($event, g.id, 'label_wb')"></v-text-field>
                                        </div>
                                        <!-- <div class="field">
                                    <v-checkbox color="#629F33" label="Отслеживать" v-model.number="g.for_task"
                                        @change="changeGoods($event, g.id, 'for_task')"></v-checkbox>
                                </div> -->
                                    </div>
                                </td>
                                <td>
                                    <div class="start">
                                        <div class="field">
                                            <span class="caption">Кол-во для<br>задачи</span>
                                            <v-text-field v-model.number="g.count_for_task" autocomplete="off" outlined
                                                @change="changeGoods($event, g.id, 'count_for_task')"
                                                class="input"></v-text-field>
                                        </div>
                                        <!-- <div class="field">
                                    <span class="caption">Логистика (OZON)</span>
                                    <v-text-field v-model.number="g.logistics_ozon" autocomplete="off" outlined
                                        class="input" @change="changeGoods($event, g.id, 'logistics_ozon')"></v-text-field>
                                </div> -->
                                        <div class="field">
                                            <span class="caption">Налог %<br>(OZON)</span>
                                            <v-text-field v-model.number="g.tax_ozon" autocomplete="off" outlined
                                                class="input"
                                                @change="changeGoods($event, g.id, 'tax_ozon')"></v-text-field>
                                        </div>
                                        <!-- <div class="field">
                                    <span class="caption">Комиссия % (OZON)</span>
                                    <v-text-field v-model.number="g.commission_ozon" autocomplete="off" outlined
                                        class="input" @change="changeGoods($event, g.id, 'commission_ozon')"></v-text-field>
                                </div> -->
                                        <div class="field">
                                            <span class="caption">Profit<br>(OZON)</span>
                                            <v-text-field v-model.number="g.profit_ozon" autocomplete="off" outlined
                                                class="input"
                                                @change="changeGoods($event, g.id, 'profit_ozon')"></v-text-field>
                                        </div>
                                        <div class="field">
                                            <span class="caption">Этикетка<br>(OZON)</span>
                                            <v-text-field v-model.number="g.label_ozon" autocomplete="off" outlined
                                                class="input"
                                                @change="changeGoods($event, g.id, 'label_ozon')"></v-text-field>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="start">
                                        <div class="field">
                                            <span class="caption">Цена / Зп<br>оператора</span>
                                            <v-text-field v-model.number="g.price" autocomplete="off" outlined
                                                class="input"
                                                @change="changeGoods($event, g.id, 'price')"></v-text-field>
                                        </div>
                                        <!-- <div class="field">
                                    <span class="caption">Логистика (AVITO)</span>
                                    <v-text-field v-model.number="g.logistics_avito" autocomplete="off" outlined
                                        class="input" @change="changeGoods($event, g.id, 'logistics_avito')"></v-text-field>
                                </div> -->
                                        <div class="field">
                                            <span class="caption">Налог %<br>(AVITO)</span>
                                            <v-text-field v-model.number="g.tax_avito" autocomplete="off" outlined
                                                class="input"
                                                @change="changeGoods($event, g.id, 'tax_avito')"></v-text-field>
                                        </div>
                                        <!-- <div class="field">
                                    <span class="caption">Комиссия % (AVITO)</span>
                                    <v-text-field v-model.number="g.commission_avito" autocomplete="off" outlined
                                        class="input"
                                        @change="changeGoods($event, g.id, 'commission_avito')"></v-text-field>
                                </div> -->
                                        <div class="field">
                                            <span class="caption">Profit<br>(AVITO)</span>
                                            <v-text-field v-model.number="g.profit_avito" autocomplete="off" outlined
                                                class="input"
                                                @change="changeGoods($event, g.id, 'profit_avito')"></v-text-field>
                                        </div>
                                        <div class="field">
                                            <span class="caption">Этикетка<br><br></span>
                                            <v-text-field v-model.number="g.label" autocomplete="off" outlined
                                                class="input"
                                                @change="changeGoods($event, g.id, 'label')"></v-text-field>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="start">
                                        <div class="field">
                                            <span class="caption">Закупочная цена<br><br></span>
                                            <v-text-field v-model.number="g.cost_price" autocomplete="off" outlined
                                                class="input"
                                                @change="changeGoods($event, g.id, 'cost_price')"></v-text-field>
                                        </div>
                                        <div class="field">
                                            <span class="caption">Налог %<br>(SBER)</span>
                                            <v-text-field v-model.number="g.tax_sber" autocomplete="off" outlined
                                                class="input"
                                                @change="changeGoods($event, g.id, 'tax_sber')"></v-text-field>
                                        </div>
                                        <div class="field">
                                            <span class="caption">Profit<br>(SBER)</span>
                                            <v-text-field v-model.number="g.profit_sber" autocomplete="off" outlined
                                                class="input"
                                                @change="changeGoods($event, g.id, 'profit_sber')"></v-text-field>
                                        </div>
                                        <div class="field">
                                            <span class="caption">Этикетка<br>(SBER)</span>
                                            <v-text-field v-model.number="g.label_sber" autocomplete="off" outlined
                                                class="input"
                                                @change="changeGoods($event, g.id, 'label_sber')"></v-text-field>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="start">
                                        <div class="field">
                                            <span class="caption">СКИДКА НА WB<br><br></span>
                                            <v-checkbox color="#629F33" v-model="g.sale_wb"
                                                @change="changeGoods($event, g.id, 'sale_wb')"></v-checkbox>
                                        </div>
                                        <div class="field">
                                            <span class="caption">Налог %<br>(YANDEX)</span>
                                            <v-text-field v-model.number="g.tax_yandex" autocomplete="off" outlined
                                                class="input"
                                                @change="changeGoods($event, g.id, 'tax_yandex')"></v-text-field>
                                        </div>
                                        <div class="field">
                                            <span class="caption">Profit<br>(YANDEX)</span>
                                            <v-text-field v-model.number="g.profit_yandex" autocomplete="off" outlined
                                                class="input"
                                                @change="changeGoods($event, g.id, 'profit_yandex')"></v-text-field>
                                        </div>
                                        <div class="field">
                                            <span class="caption">Этикетка<br>(YANDEX)</span>
                                            <v-text-field v-model.number="g.label_yandex" autocomplete="off" outlined
                                                class="input"
                                                @change="changeGoods($event, g.id, 'label_yandex')"></v-text-field>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </TableBlock>
                </Block>
            </template>
        </Grid>
        <Modal ref="specprice" :notFooterBorder="true" padding="20" :hideHeader="true" size="small">
            <template v-slot:body>
                <div class="spec-form" v-if="parseData">
                    <input v-show="false" type="file" ref="file" accept=".xlsx" @change="onFileSelected" />
                    <v-btn variant="outlined" class="btn-w-border" @click="$refs['file'].click()">
                        <template v-if="specForm.file?.name">
                            {{ specForm.file.name }}
                        </template>
                        <template v-else>
                            Выберите файл
                            <Icon width="24" height="24" viewBox="0 0 24 24" strockeWidth="2" :iconName="'attach'" />
                        </template>
                    </v-btn>
                    <div class="grid-row">
                        <span>Укажите колонку артикула:</span>
                        <v-text-field v-model.number="specForm.article" autocomplete="off" outlined
                            class="input"></v-text-field>
                    </div>
                    <div class="grid-row">
                        <span>Укажите колонку плановой цены:</span>
                        <v-text-field v-model.number="specForm.price" autocomplete="off" outlined
                            class="input"></v-text-field>
                    </div>
                    <v-btn type="submit" class="button mt-6" variant="tonal" color="primary" @click="parsing()">
                        Распарсить
                    </v-btn>
                </div>
                <div v-else>
                    <TableBlock>
                        <template v-slot:head>
                            <tr v-if="!isMobyle">
                                <th></th>
                                <th>#</th>
                                <th>Предлагаемая цена</th>
                                <th>Ozon</th>
                                <th>WB</th>
                                <th>Авито</th>
                            </tr>
                        </template>
                        <template v-slot:body>
                            <tr v-for="(gID, info) of parseData" :key="'ng-' + gID">
                                <th>
                                    <v-checkbox color="#629F33" label="Отслеживать"></v-checkbox>
                                </th>
                                <th>{{ gID }}</th>
                                <th>{{ info.price }}</th>
                                <th>{{ info.ozon }}</th>
                                <th>{{ info.wb }}</th>
                                <th>{{ info.avito }}</th>
                            </tr>
                        </template>
                    </TableBlock>
                </div>
            </template>
        </Modal>
    </div>
</template>
<script>
import { mapState } from 'vuex'
// import { actionTypes } from '@/store/modules/auth'
import { globalMethods } from '@/mixins/globalMethods'

export default {
    name: 'GoodsPage',
    // components: {
    // },
    mixins: [globalMethods],
    created() {
        if (!this.checkAccess(this.$route.name)) {
            this.$router.push({ name: 'Main' });
        }
    },
    mounted() {
        this.filter()
    },
    data() {
        return {
            goods_id: null,
            parseData: null,
            specForm: {
                file: null,
                article: 'A',
                price: 'B'
            },
            forTask: true,
            currentpage: 0,
            pageCount: 0,
            goodsList: [],
            selectGoods: null,
            mp: ['wb', 'ozon', 'avito']
        }
    },
    computed: {
        ...mapState({
            windowWidth: state => state.windowWidth
        }),
        isMobyle() {
            return this.windowWidth <= 560
        },
        thisPage() {
            return this.currentpage + 1
        },
    },
    watch: {
        'goods_id': function (val) {
            this.currentpage = 0;
        },
        currentpage: function (val) {
            this.filter()
        },
        forTask: function (val) {
            this.filter()
        },
        'specForm.price': function (val) {
            if (/^[a-z]+$/i.test(val)) {
                this.$set(this.specForm, 'price', val.toUpperCase())
            } else {
                this.$set(this.specForm, 'price', 'B')
            }
        },
        'specForm.article': function (val) {
            if (/^[a-z]+$/i.test(val)) {
                this.$set(this.specForm, 'article', val.toUpperCase())
            } else {
                this.$set(this.specForm, 'article', 'A')
            }
        },
    },
    methods: {
        deleteGoods(gID, ind) {
            const answer = confirm('Вы точно хотите удалить товар?')
            if (answer) {
                this.sendRequest('DELETE', { id: gID }, '/delete_goods', {}, true, true)
                    .then(response => {
                        this.$delete(this.goodsList, ind)

                    })
                    .catch(err => {
                        this.$store.commit('setError', err)
                    })
            }
        },
        onFileSelected(event) {
            this.$set(this.specForm, 'file', this.$refs.file.files[0])
        },
        parsing() {
            if (this.specForm.article && this.specForm.price && this.specForm.file) {
                let file = this.specForm.file;

                let formData = new FormData();
                formData.append("file", file);
                formData.append("article", this.specForm.article);
                formData.append("price", this.specForm.price);
                this.sendRequest('POST', formData, '/parsing_file', {
                    "content-type": "multipart/form-data"
                }, true, true)
                    .then(response => {

                    })
                    .catch(err => {
                        this.$store.commit('setError', err)
                    })
            }
        },
        filter() {
            this.sendRequest('GET', { page: this.currentpage, id: this.goods_id }, '/get_goods', {}, true, true)
                .then(response => {
                    let arr1 = [],
                        arr2 = []
                    response.map(r => {
                        if (
                            !r.min_count_for_transfer &&
                            !r.count_for_task &&
                            !r.profit_avito &&
                            !r.profit_wb &&
                            !r.profit_ozon
                        ) {
                            arr1.push(r)
                        } else {
                            arr2.push(r)
                        }
                    })
                    // this.$set(this, 'pageCount', Math.ceil(response.count / 100))                    
                    this.$set(this, 'goodsList', [...arr1, ...arr2])
                })
                .catch(err => {
                    this.$store.commit('setError', err)
                })
        },
        changePage(action) {
            if (action == 'next') {
                let nextPage = this.currentpage + 1
                if (nextPage <= this.pageCount) {
                    this.$set(this, 'currentpage', nextPage)
                } else {
                    this.$set(this, 'currentpage', this.pageCount)
                }
            } else {
                let prevPage = this.currentpage - 1
                if (prevPage > 0) {
                    this.$set(this, 'currentpage', prevPage)
                } else {
                    this.$set(this, 'currentpage', 0)
                }
            }
        },
        changeGoods(value, id, items) {
            this.sendRequest('PUT', { value, id, items }, '/change_goods', {}, true, true)
                .then(response => {
                    // this.$set(this, 'goodsList', response)
                })
                .catch(err => {
                    this.$store.commit('setError', err)
                })
        },
        uploadImg() {
            let files = this.$refs.goodsimg.files;

            let formData = new FormData();
            for (var i = 0; i < files.length; i++) {
                let file = files[i];
                formData.append("photo", file);
            }
            formData.append("id", this.selectGoods);
            this.sendRequest('PUT', formData, '/upload_file', {
                "content-type": "multipart/form-data"
            }, true, true)
                .then(response => {
                    let findIndex = this.goodsList.findIndex(g => g.id == this.selectGoods)
                    this.$set(this.goodsList[findIndex], 'photo', response.img)
                    this.$set(this, 'selectGoods', null)
                })
                .catch(err => {
                    this.$store.commit('setError', err)
                })
        },
    }
}
</script>
<style lang="scss">
#goods-page {
    .v-input__slot {
        margin-bottom: 0 !important;
    }

    .v-text-field__details {
        display: none !important;
    }

    table {
        height: 100%;

        tr {
            @media screen and (max-width: 560px) {
                display: grid;
                gap: 3px;
                grid-template-columns: repeat(3, calc(33% - 3px));
            }
        }

        td {
            height: 100%;

            &:first-child {
                font-weight: bold;
            }

            &:nth-child(5),
            &:nth-child(6),
            &:nth-child(7) {
                vertical-align: top;
            }

            @media screen and (max-width: 560px) {
                border-bottom: none;
            }

            &:nth-child(4) {
                @media screen and (max-width: 560px) {
                    grid-row: 3;
                    grid-column: 1 / 4;
                    border-bottom: 1px solid #e2e2e2;
                }
            }

            .caption {
                font-weight: bold;
                font-size: 9pt;
                margin-bottom: 3px;
                display: block;
            }

            .field {
                &.full-height {
                    height: 100%;

                    .v-textarea,
                    .v-input__control,
                    .v-input__slot {
                        height: 100%;
                    }
                }

                &:not(:last-child) {
                    margin-bottom: 5px;
                }
            }

            .no-photo {
                width: 150px;
                height: 150px;
                border-radius: 50%;
                background: #F1F1F1;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }

            img {
                cursor: pointer;
            }
        }
    }

    .table-contoll {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 5px;

        >div {
            flex: 1;
        }

        >span {
            margin-right: 10px;
        }
    }

    .spec-form {
        .grid-row {
            display: grid;
            grid-template-columns: 1fr 50px;
            align-items: center;
            margin-top: 10px;
        }
    }

    .grid-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;

        >div {
            // flex: 1;
        }

        form {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 5px;
            align-items: flex-end;

            @media screen and (max-width: 576px) {
                grid-template-columns: 100%;
            }
        }
    }

    .start {
        height: 100%;
    }

    .max-w {
        max-width: 30%;
        width: 30%;
    }

    .trash-div {
        padding-top: 50px;
    }
}
</style>

<template>
    <div id="scanner-page">
        <Grid :column="1" :gap="20">
            <!-- :style="{ overflow: 'hidden' }" -->
            <Block>
                <Grid :column="1" :gap="5" :style="{ alignItems: 'center', padding: '5px' }">
                    <div>
                        <!-- <StreamBarcodeReader @decode="onDecode" @loaded="onLoaded" v-if="scanning && barcode != null">
                        </StreamBarcodeReader> -->
                        <!-- <div id="interactive" v-if="scanning && barcode == null"></div> -->
                        <!-- <vue-qrcode-reader @on-decode="handleQRCode" v-if="scanning == 'place'" /> -->
                    </div>
                    <div>
                        <Grid :column="1" :gap="5">
                            <!-- <v-btn type="submit" class="button mt-6" variant="tonal" color="primary" @click="startScanner()"
                                v-if="goodsData === null">
                                Сканировать
                            </v-btn> -->
                            <div>
                                <template v-if="goodsData !== null">
                                    <img :src="goodsData.picture" v-if="goodsData.picture">
                                    <div class="row">
                                        <div>Артикул</div>
                                        <div>{{ goodsData.id }}</div>
                                    </div>
                                    <div class="row">
                                        <div>Название</div>
                                        <div>{{ goodsData.name }}</div>
                                    </div>
                                    <div class="row">
                                        <div>Остаток<br>на складе</div>
                                        <div>{{ goodsData.stock2[0] }}</div>
                                    </div>
                                    <div class="row">
                                        <div>Место<br>на складе</div>
                                        <div>
                                            <ul v-if="goodsData.place">
                                                <li v-for="(p, ind) in goodsData.place" :key="'p' + ind"> {{ p }}</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <v-btn type="submit" class="button mt-6 fiolet" variant="tonal" color="fiolet"
                                        @click="clearPlace()">
                                        Присвоить место на складе
                                    </v-btn>

                                    <v-btn type="submit" class="button mt-6 purpur" variant="tonal" color="purpur"
                                        @click="$refs.scanField.focus();">
                                        Добавить место на складе
                                    </v-btn>
                                </template>
                            </div>
                            <v-text-field ref="scanField" hide-details="true" v-model="scannedData" autocomplete="off"
                                outlined class="input"></v-text-field>
                        </Grid>
                    </div>
                </Grid>
            </Block>
            <!-- <Block>
                <div id="interactive" style="width: 100%; height: 300px;"></div>
                <button @click="startScanner">Старт</button>
                <button @click="stopScanner">Стоп</button>
                <div>Результат: {{ scannedBarcode }}</div>
            </Block> -->
        </Grid>
    </div>
</template>
<script>
import { mapState } from 'vuex'
// import { actionTypes } from '@/store/modules/auth'
import { globalMethods } from '@/mixins/globalMethods'
import Quagga from 'quagga';
// import 'es-module-shims';
// import VueQrcodeReader from "vue-qrcode-reader";
// import { StreamBarcodeReader } from "vue-barcode-reader";
import { debounce } from 'lodash'
export default {
    name: 'ScannerPage',
    components: {
        // StreamBarcodeReader
        //     VueQrcodeReader,
    },
    mixins: [globalMethods],
    computed: {
        ...mapState({
            windowWidth: state => state.windowWidth
        }),
        isMobyle() {
            return this.windowWidth <= 560
        },
        thisPage() {
            return this.currentpage + 1
        },
    },
    mounted() {
        this.onDecode = debounce(this.onDecode, 500)
        this.$nextTick(() => {
            this.$refs.scanField.focus();
        });
    },
    data() {
        return {
            scannedData: null,
            updFirst: false,
            scannedBarcode: null,
            scanning: false,
            barcode: null,
            place: null,
            wait: false,
            goodsData: null,
        }
    },
    watch: {
        // scanning: function (val) {
        //     if (val == true && this.barcode == null) {
        //         let self = this
        //         setTimeout(() => {
        //             Quagga.init({
        //                 inputStream: {
        //                     name: 'Live',
        //                     type: 'LiveStream',
        //                     target: document.querySelector('#interactive'),
        //                 },
        //                 decoder: {
        //                     readers: ['ean_reader'], // Можете выбрать другие типы штрих-кодов
        //                 },
        //             }, function (err) {
        //                 if (err) {
        //                     console.error(err);
        //                     return;
        //                 }
        //                 Quagga.start();
        //             });
        //             Quagga.onDetected(self.onDecode);
        //         }, 10)

        //     } else {
        //         Quagga.offDetected(this.onDecode);
        //         Quagga.stop();
        //     }
        // },
        scannedData: function (val) {
            if (!!val) {
                this.onDecode(val)
            }
        }
    },
    methods: {
        clearPlace(){
            this.updFirst = true;
            this.$refs.scanField.focus();
        },
        getGoodsByBarcode(barcode) {
            this.scanning = false
            this.sendRequest('POST', { barcode }, '/get_goods_by_barcode', true, true)
                .then(response => {
                    if (response?.[0]) {
                        this.goodsData = response[0]
                    } else {
                        this.barcode = null
                        this.$store.commit('setError', `Товар ${barcode} не найден`)
                    }
                })
                .catch(err => {
                    this.barcode = null
                    this.$store.commit('setError', err)
                })
        },
        addPlace(place) {
            this.scanning = false
            this.sendRequest('POST', { place, barcode: this.barcode, updFirst: this.updFirst == true ? 1 : null }, '/add_goods_place', true, true)
                .then(response => {
                    this.scannedBarcode = null
                    this.goodsData = null
                    this.barcode = null
                    this.updFirst = false
                    this.scannedData = null                    
                    alert(`ЗАПИСАНО: полка ${place}`)
                    this.$refs.scanField.focus();
                })
                .catch(err => {
                    this.$store.commit('setError', err)
                })
        },
        startScanner(fitstUpd) {
            this.scanning = true
            this.updFirst = fitstUpd
        },

        onDecode(text) {
            if (this.wait == false) {
                if (!this.barcode || this.goodsData == null) {
                    this.getGoodsByBarcode(text)
                    this.barcode = text
                    this.scannedData = null
                } else {
                    this.place = text
                    this.addPlace(text, this.updFirst)
                }
            }
        },

        onLoaded() {
            console.log(`Ready to start scanning barcodes`)
        }
    }
}
</script>
<style lang="scss">
#stocks-page {
    .table-scroll {
        height: 100vh;

        thead {
            position: sticky;
            top: 0;
            z-index: 3;
        }

        tbody {
            tr {
                &.red {
                    td {
                        background: rgba(159, 51, 51, 0.376);
                    }
                }

                &.green {
                    td {
                        background: rgba(98, 159, 51, 0.376);
                    }
                }

                &.blue {
                    td {
                        background: rgba(51, 114, 159, 0.376);
                    }
                }
            }
        }
    }

    .btn-w-border {
        max-width: 200px !important;
        overflow: hidden;
    }
}

#interactive {
    width: 300px;
    min-width: 300px;
    max-width: 300px;
    width: 300px;
    height: 300px;
    min-height: 300px;
    max-height: 300px;

    video {
        max-height: 300px !important;
        min-height: 300px !important;
        height: 300px !important;
        max-width: 300px !important;
        min-width: 300px !important;
        width: 300px !important;
        margin: auto;
    }

    canvas {
        display: none;
    }
}

.v-btn.purpur {
    background: #A13453 !important;
    color: #fff !important;
    width: 100%;
}

.v-btn.fiolet {
    background: #7034a1 !important;
    color: #fff !important;
    width: 100%;
    margin-bottom: 20px;
}

.row {
    margin-bottom: 7px;
    padding-bottom: 3px;
    border-bottom: 1px solid #000;
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    gap: 10px;

    div {
        &:first-child {
            font-weight: bold;
        }

        &:last-child {
            text-align: right;
        }
    }

    ul {
        padding-left: 20px;
    }
}

#scanner-page {
    img {
        width: 60%;
        display: block;
        margin: auto;
    }
}
</style>